import { get } from 'src/http/get';

export const downloadInvoiceCsv = (invoiceId: string) =>
  get('/api/invoices/csv-export/' + invoiceId).then((response) => {
    if (response.status === 200) {
      response.blob().then((blob) => {
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = `faktura-${invoiceId}.csv`; // Use extracted filename
        a.click();

        URL.revokeObjectURL(url);
      });
    }
  });
