import { gql } from '@apollo/client';

export const FIND_HISTORICAL_EMPLOYEE_CARD_AGGREGATED_WAGES = gql`
  query FindEmployeeCardHistoricalAggregatedWages(
    $employeeId: UUID!
    $fromPeriod: String!
    $toPeriod: String!
    $allowDrafts: Boolean
  ) {
    employeeCardHistoricalAggregatedWages(
      employeeId: $employeeId
      fromPeriod: $fromPeriod
      toPeriod: $toPeriod
      allowDrafts: $allowDrafts
    ) {
      id
      employeeId
      employmentGroupId
      employmentCategory
      evaluationPolicyEntityId
      remunerationType
      sickDays
      workingPercentage
      partTimePension
      employmentType
      period
      aggregatedWageTypeWages {
        employerWageTypeId
        amount
        extentAdjustedAbsenceDays
        wages {
          id
          wageType
          employerWageTypeId
          payTypeName
          payTypeNumber
          kubTypes
          amount
          payedAmount
          currency
          fromDate
          toDate
          quantityValue
          quantityUnit
          inUse
          isDraftModified
          extentAdjustedAbsenceDays
        }
      }
      aggregatedPayTypeWages {
        payTypeNumber
        payTypeName
        payedAmount
        wages {
          id
          wageType
          payTypeName
          payTypeNumber
          kubTypes
          amount
          payedAmount
          fromDate
          toDate
          inUse
          isDraftModified
        }
      }
    }
  }
`;
