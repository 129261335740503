import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import { SearchInvoiceRowsFilters, SearchInvoiceRowRequest } from 'src/types/invoice/SearchInvoiceRowsRequest';
import When from 'src/components/Common/When';
import './invoice-rows-filter-details.scss';

interface InvoicesFilterDetailsProps {
  searchRequest: SearchInvoiceRowRequest;
  applyFilters: (filters: SearchInvoiceRowsFilters) => void;
}

export const InvoicesFilterDetails: FC<InvoicesFilterDetailsProps> = ({ searchRequest, applyFilters }) => {
  const { t } = useTranslation();
  const hasInsuranceProviderFilters = searchRequest.filters?.insuranceProviderIdsFilter?.length > 0;

  const removeInsuranceMappingIdsFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceProviderIdsFilter: null,
    });
  };

  return (
    <div className="invoice-rows-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      <Typography variant="caption" italic>
        Inga filter
      </Typography>
      <When condition={hasInsuranceProviderFilters}>
        <FilterButton
          label={
            searchRequest.filters?.insuranceProviderIdsFilter?.length +
            ' ' +
            t('invoice:invoice-rows.filters-modal.insurance-mapping-ids-label')
          }
          onClick={removeInsuranceMappingIdsFilter}
        />
      </When>
    </div>
  );
};
export default InvoicesFilterDetails;
