import { TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FloatingAddButton from 'src/components/Common/FloatingAddButton';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import TableBase from 'src/components/Common/TableBase/TableBase';
import { ACCOUNTING_UNITS } from 'src/graphql/schema/query/accountingunit/AccountingUnits';
import { AccountingUnit } from 'src/types/accountingunit/AccountingUnit';
import AddAccountingUnitModal from './AddAccountingUnitModal';
import { useTranslation } from 'react-i18next';

const AccountingUnitsTab = () => {
  const { t } = useTranslation();
  const { employerId, customerName } = useParams();
  const navigate = useNavigate();
  const [addAccountingUnitModalOpen, setAddAccountingUnitModalOpen] = useState(false);

  const handleOpenAccountingUnitModal = () => setAddAccountingUnitModalOpen(true);
  const handleCloseAccountingUnitModal = () => setAddAccountingUnitModalOpen(false);

  return (
    <QueryWrapper query={ACCOUNTING_UNITS}>
      {(accountingUnits: AccountingUnit[]) => {
        return (
          <>
            <TableBase>
              <TableHead>
                <TableRow>
                  <Th>{t('economySettings:accounting-unit-tab.table.name')}</Th>
                  <Th>{t('economySettings:accounting-unit-tab.table.description')}</Th>
                  <Th>{t('economySettings:accounting-unit-tab.table.default-accounting-unit')}</Th>
                </TableRow>
              </TableHead>
              <TableBody>
                {accountingUnits.map((accountingUnit) => (
                  <TableRow
                    key={`AccountingUnitTableRow-${accountingUnit.id}`}
                    onClick={() =>
                      navigate(
                        `/account/${customerName}/${employerId}/economy-settings/accounting-unit/${accountingUnit.id}`,
                      )
                    }
                  >
                    <Td>{accountingUnit.name}</Td>
                    <Td>{accountingUnit.description}</Td>
                    <Td>
                      {accountingUnit.defaultAccountingUnit
                        ? t('economySettings:accounting-unit-tab.table.default-accounting-unit-label')
                        : ''}
                    </Td>
                  </TableRow>
                ))}
              </TableBody>
            </TableBase>
            <FloatingAddButton
              label={t('economySettings:accounting-unit-tab.add-button')}
              onClick={handleOpenAccountingUnitModal}
            />
            <AddAccountingUnitModal
              accountingUnits={accountingUnits}
              open={addAccountingUnitModalOpen}
              onClose={handleCloseAccountingUnitModal}
            />
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default AccountingUnitsTab;
