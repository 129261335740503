import { Button, Icon, Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { stringOrBlank } from 'src/util/StringUtil';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import VerifyDrawerCheckpointsTab from './CheckpointsTab/VerifyDrawerDrawerCheckpointsTab';
import ApolloServiceWrapper from 'src/components/Common/ApolloServiceWrapper';
import withGuard from 'src/components/Common/WithGuard/withGuard';
import CheckpointsInsuranceEventsTab from './InsuranceEventsTab';
import useEmployeeInsuranceEvents from './useEmployeeInsuranceEvents';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventDetailDrawer from '../InsuranceEvents/InsuranceEventsDrawer/InsuranceEventDetailsDrawer';
import { VerifyDrawerTab } from 'src/types/tabs/VerifyDrawerTab';
import { useNavigate } from 'react-router-dom';

interface VerifyDrawerProps {
  open: boolean;
  handleClose: () => void;
  eventReport: InsuranceEventReportEmployeeResponse;
  fromPeriod: string;
  toPeriod: string;
}

const VerifyDrawer: React.FC<VerifyDrawerProps> = ({ open, handleClose, eventReport, fromPeriod, toPeriod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const employee = eventReport.employee;
  const service = useEmployeeInsuranceEvents(employee.id, fromPeriod, toPeriod, eventReport.checkpoints);
  const [selectedInsuranceEvent, setSelectedInsuranceEvent] = useState<InsuranceEvent>();
  const [currentTab, setCurrentTab] = useState<VerifyDrawerTab>(VerifyDrawerTab.CHECKPOINTS);

  const backToVerifyDrawer = () => {
    setSelectedInsuranceEvent(undefined);
    setCurrentTab(VerifyDrawerTab.INSURANCE_EVENTS);
  };

  const handleSelectEvent = (event: InsuranceEvent) => {
    setSelectedInsuranceEvent(event);
  };

  const navigateToProfile = () => {
    navigate(`/employees/${employee.id}`);
  };

  const insuranceEvents = service.status == 'loaded' ? service.payload.employeeInsuranceEvents : [];
  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={handleClose}>
      {!selectedInsuranceEvent && (
        <DrawerBase
          leftButton={
            <Button
              type="link"
              label={t('report:employee-drawer.go-to-profile')}
              iconPlacement="left"
              icon={<Icon type="User" className="employee-drawer__button__icon" />}
              onClick={navigateToProfile}
            />
          }
          onClose={handleClose}
          title={`${stringOrBlank(employee?.firstName)} ${stringOrBlank(employee?.lastName)}`}
          subTitle={stringOrBlank(employee?.personNumber)}
        >
          <ApolloServiceWrapper service={service}>
            <Tabs tabIndex={currentTab}>
              <Tab title={t('verify:drawer.controlPoints')}>
                <VerifyDrawerCheckpointsTab
                  eventReport={eventReport}
                  insuranceEvents={insuranceEvents}
                  onFinalize={handleClose}
                />
              </Tab>
              <Tab title={t('verify:drawer.insuranceEvents')}>
                <CheckpointsInsuranceEventsTab insuranceEvents={insuranceEvents} onSelectEvent={handleSelectEvent} />
              </Tab>
            </Tabs>
          </ApolloServiceWrapper>
        </DrawerBase>
      )}
      {selectedInsuranceEvent && (
        <InsuranceEventDetailDrawer
          employee={eventReport.employee}
          onBackClick={backToVerifyDrawer}
          insuranceEvent={selectedInsuranceEvent}
          onClose={handleClose}
        />
      )}
    </DrawerModal>
  );
};

export default withGuard(VerifyDrawer, (props) => props.open);
