import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';

export const groupBySubActionCategory = (
  eventReport: InsuranceEventReportEmployeeResponse,
): Map<string, InsuranceEventCheckpoint[]> => {
  return eventReport.checkpoints.reduce((map, eventReport) => {
    const grouped = map.get(eventReport.subActionCategory);

    if (grouped) {
      grouped.push(eventReport);
    } else {
      map.set(eventReport.subActionCategory, [eventReport]);
    }

    return map;
  }, new Map<string, InsuranceEventCheckpoint[]>());
};

export const groupByInsuranceEvent = (
  checkpoints: InsuranceEventCheckpoint[],
): Map<string, InsuranceEventCheckpoint[]> => {
  return checkpoints.reduce((map, checkpoint) => {
    const grouped = map.get(checkpoint.insuranceEventType);

    if (grouped) {
      grouped.push(checkpoint);
    } else {
      map.set(checkpoint.insuranceEventType, [checkpoint]);
    }
    return map;
  }, new Map<string, InsuranceEventCheckpoint[]>());
};

export const getUsedProviders = (checkPoints: InsuranceEventCheckpoint[], providers: InsuranceProvider[]) =>
  [
    ...checkPoints
      .reduce((acc, checkPoints) => acc.add(checkPoints.additionalData.insuranceProviderId), new Set<string>())
      .values(),
  ].reduce((acc, providerId) => {
    const provider = providers.find((provider) => provider.id == providerId);
    if (provider) {
      acc.push(provider);
    }
    return acc;
  }, []);
