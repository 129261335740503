import React, { FunctionComponent } from 'react';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import { GroupedAffiliationRules, AffiliationRuleGroupType } from 'src/types/policy/GroupedAffiliationRules';
import { PolicyRuleType } from 'src/types/policy/AffiliationRule';
import EmploymentRemunerationTypeRule from './EmploymentRemunerationTypeRule';
import EmploymentCategoryRule from './EmploymentCategoryRule';
import AgeLimitRule from './AgeLimitRule';
import YearOfBirthLimitRule from './YearOfBirthLimitRule';
import EmploymentDateRule from './EmploymentDateRule';
import LeaveLimitRuleGroup from './LeaveLimitRuleGroup';
import SickLeaveLimitRuleGroup from './SickLeaveLimitRuleGroup';
import EmploymentTypeRule from './EmploymentTypeRule';
import WageLimitRuleGroup from './WageLimitRuleGroup';
import { UpdateOrDeleteAffiliationRuleRequest } from 'src/types/policy/request/UpdateOrDeleteAffiliationRuleRequest';
import IndividualChoiceRuleGroup from './IndividualChoiceRuleGroup';
import { EmployerIndividualChoice } from 'src/types/employer/EmployerIndividualChoice';

interface AffiliationRuleGroupProps {
  selectedRuleGroupType: AffiliationRuleGroupType;
  policyEntityState: PolicyState;
  selectedRuleGroup: GroupedAffiliationRules;
  onUpdateOrDeleteAffiliationRule: (request: UpdateOrDeleteAffiliationRuleRequest) => void;
  policyId: string;
  employerId: string;
  onBackClick?: () => void;
  employerIndividualChoices: EmployerIndividualChoice[];
}

const AffiliationRuleGroupDetails: FunctionComponent<AffiliationRuleGroupProps> = ({
  selectedRuleGroupType,
  policyEntityState,
  selectedRuleGroup,
  onUpdateOrDeleteAffiliationRule,
  policyId,
  employerId,
  employerIndividualChoices,
}) => {
  switch (selectedRuleGroupType) {
    case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_TYPE:
      return (
        <EmploymentTypeRule
          policyState={policyEntityState}
          affiliationRule={selectedRuleGroup?.affiliationRules.find(
            (ruleType) => ruleType.policyRuleType === PolicyRuleType.EMPLOYMENT_TYPE,
          )}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_REMUNERATION_TYPE:
      return (
        <EmploymentRemunerationTypeRule
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_CATEGORY:
      return (
        <EmploymentCategoryRule
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );
    case AffiliationRuleGroupType.RULE_GROUP_AGE_LIMIT:
      return (
        <AgeLimitRule
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
          employerIndividualChoices={employerIndividualChoices}
        />
      );
    case AffiliationRuleGroupType.RULE_GROUP_YEAR_OF_BIRTH_LIMIT:
      return (
        <YearOfBirthLimitRule
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
          policyRuleType={PolicyRuleType.YEAR_OF_BIRTH_LIMIT}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_START_DATE:
      return (
        <EmploymentDateRule
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          policyRuleType={PolicyRuleType.EMPLOYMENT_START_DATE}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );
    case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_END_DATE:
      return (
        <EmploymentDateRule
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          policyRuleType={PolicyRuleType.EMPLOYMENT_END_DATE}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_LEAVE_LIMIT:
      return (
        <LeaveLimitRuleGroup
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_SICK_LEAVE_LIMIT:
      return (
        <SickLeaveLimitRuleGroup
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_WAGE_LIMIT:
      return (
        <WageLimitRuleGroup
          policyId={policyId}
          employerId={employerId}
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
        />
      );

    case AffiliationRuleGroupType.RULE_GROUP_INDIVIDUAL_CHOICE:
      return (
        <IndividualChoiceRuleGroup
          policyState={policyEntityState}
          selectedAffiliationRuleGroup={selectedRuleGroup}
          onUpdateOrDeleteAffiliationRule={onUpdateOrDeleteAffiliationRule}
          employerIndividualChoices={employerIndividualChoices}
        />
      );

    default:
      break;
  }
};

export default AffiliationRuleGroupDetails;
