import { Button, Checkbox, Collapsible, InlineEdit, ListGroup, Select } from 'fgirot-k2-ui-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import When from 'src/components/Common/When';
import InfoMessage from 'src/components/Common/InfoMessage';
import {
  ReEmploymentCheckpointState,
  UpsertReEmploymentCheckpointStateRequest,
} from 'src/types/reporting/ReEmploymentCheckpointState';
import { Employee } from 'src/types/Employee';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { useUpsertReEmploymentCheckpointState } from 'src/service/reporting/UpsertReEmploymentCheckpointState';
import './update-reemployment.scss';

interface UpdateReemploymentProps {
  employee: Employee;
  checkpoint: EmploymentEventCheckpoint;
  employerId: string;
  checkpointState: ReEmploymentCheckpointState;
  onBackClick: () => void;
}

const UpdateReemployment: React.FC<UpdateReemploymentProps> = ({
  checkpoint,
  employee,
  employerId,
  checkpointState,
  onBackClick,
}) => {
  const { t } = useTranslation();
  const [selectedReemploymentOption, setSelectedReemploymentOption] = useState<boolean | undefined>(
    checkpointState?.isReEmployment,
  );
  const [selectedStartDate, setSelectedStartDate] = useState<string>(
    checkpointState?.newEmploymentStartDate || employee.employmentStartDate,
  );
  const [keepHistory, setKeepHistory] = useState<boolean | undefined>(checkpointState?.preserveHistory);

  const upsertReEmploymentCheckpointState = useUpsertReEmploymentCheckpointState();

  const reEmploymentOptions = [
    { label: t('common:yes'), value: true },
    { label: t('common:no'), value: false },
  ];

  const isButtonDisabled = !reEmploymentOptions.find((option) => option.value === selectedReemploymentOption);

  useEffect(() => {
    if (checkpointState) {
      setSelectedReemploymentOption(checkpointState.isReEmployment);
      setSelectedStartDate(checkpointState.newEmploymentStartDate || employee.employmentStartDate);
      setKeepHistory(checkpointState.preserveHistory);
    }
  }, [checkpointState]);

  const handleReemploymentOptionChange = (value: boolean) => {
    if (!value) {
      setSelectedStartDate('');
      setKeepHistory(false);
    }
    setSelectedReemploymentOption(value);
  };

  const handleOnChange = (__field: string, value: string) => {
    setSelectedStartDate(value);
  };

  const handleCheckboxUpdate = (checked: boolean) => {
    setKeepHistory(checked);
  };

  const handleSubmit = () => {
    const request: UpsertReEmploymentCheckpointStateRequest = {
      checkpointId: checkpoint.id,
      isReEmployment: selectedReemploymentOption ?? false,
      newEmploymentStartDate: selectedReemploymentOption ? selectedStartDate || null : null,
      preserveHistory: selectedReemploymentOption ? keepHistory ?? null : null,
      checkpointEmployeeId: employee.id,
    };

    upsertReEmploymentCheckpointState(request, employerId).then(() => {
      onBackClick();
    });
  };

  return (
    <div className="update-reemployment">
      <ListGroup variant="inline-edit" className="update-reemployment">
        <InlineEdit label={t('examination:drawer.is-reemployment-title')} bold>
          <Select
            small
            placeholder={t('common:select')}
            options={reEmploymentOptions}
            selected={reEmploymentOptions.find((option) => option.value === selectedReemploymentOption)}
            onChange={(option) => handleReemploymentOptionChange(option.value)}
            allowDropdownMaxWidth={false}
          />
        </InlineEdit>
      </ListGroup>

      <Collapsible open={selectedReemploymentOption === true}>
        <ListGroup variant="inline-edit">
          <When condition={selectedReemploymentOption === true}>
            <InlineEdit bold label={t('examination:drawer.reemployment-start-date')}>
              <UpdateDatePicker fieldKey="employmentStartDate" onBlur={handleOnChange} fieldValue={selectedStartDate} />
            </InlineEdit>
            <InlineEdit bold label={t('examination:drawer.keep-history-checkbox')}>
              <Checkbox checked={keepHistory} onChange={(e) => handleCheckboxUpdate(e.target.checked)} />
            </InlineEdit>
          </When>
        </ListGroup>
      </Collapsible>

      {selectedReemploymentOption === false && (
        <InfoMessage message={t('examination:drawer.no-reemployment-message')} />
      )}
      <Button
        className="update-reemployment__save-button"
        label={t('common:save')}
        disabled={isButtonDisabled}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default UpdateReemployment;
