import { Button, Icon } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import DeleteInvoiceModal from 'src/components/Economy/InvoicePage/DeleteInvoiceModal';
import InvoiceHeader from 'src/components/Economy/InvoicePage/InvoiceHeader';
import EmployeeMissingBanner from 'src/components/Economy/InvoicesTab/InvoiceDrawer/CheckpointBanners/EmployeeMissingBanner';
import PageBase from 'src/components/PageBase';
import { GET_INVOICE } from 'src/graphql/schema/query/invoice/GetInvoice';
import { downloadInvoiceCsv } from 'src/service/invoice/DownloadInvoiceCsv';
import { useNavigateWithFallback } from 'src/service/routes/LocationHooks';
import { InvoicePage } from 'src/types/invoice/Invoice';
import InvoiceRowOverview from 'src/components/Economy/InvoicePage/InvoiceRowsOverview/InvoiceRowsOverview';

const InvoiceDetailsPage = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { invoiceId } = useParams();
  const { t } = useTranslation();

  const handleBackClick = useNavigateWithFallback(-1, '/');
  const handleDownloadInvoiceClick = () => downloadInvoiceCsv(invoiceId);
  const handleOpenDeleteModal = () => setDeleteModalOpen(true);
  const handleCloseDeleteModal = () => setDeleteModalOpen(false);

  return (
    <QueryWrapper query={GET_INVOICE} options={{ fetchPolicy: 'no-cache', variables: { invoiceId } }}>
      {(invoice: InvoicePage) => {
        return (
          <PageBase header title={t('invoice:details.title')} handleBackButton={handleBackClick}>
            <EmployeeMissingBanner employerId={invoice.employer.id} errandId={invoice.errandId} />
            <div className="invoice-header__actions">
              <Button
                className="invoice-header__actions--button"
                label={t('invoice:details.download-button')}
                onClick={handleDownloadInvoiceClick}
              />
              <Button
                className="invoice-header__actions--button"
                size="small"
                type="secondary"
                icon={<Icon type="Trash" />}
                onClick={handleOpenDeleteModal}
                data-cy="invoice-page__delete-button"
                disabled={
                  invoice.invoiceStatus === 'INVOICE_PAID' || invoice.invoiceStatus === 'INVOICE_PAYMENT_REQUEST_SENT'
                }
              />
            </div>
            <DeleteInvoiceModal
              open={deleteModalOpen}
              handleClose={handleCloseDeleteModal}
              invoiceId={invoiceId}
              employerId={invoice.employer.id}
            />
            <InvoiceHeader invoice={invoice} />
            <InvoiceRowOverview />
          </PageBase>
        );
      }}
    </QueryWrapper>
  );
};

export default InvoiceDetailsPage;
