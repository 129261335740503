import { TableHead, TableRow, Th, TableBody } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Employee } from 'src/types/Employee';
import ValidatingTableRow from './ValidatingTableRow';
import ReportEmployeeDrawer from '../../Common/ReportEmployeeDrawer';
import UpdateEmployeeTable from './UpdateEmployeeTable';
import { DataValidationEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import TableBase from 'src/components/Common/TableBase';
import { useFullEmployers } from 'src/service/employer/GetEmployer';

interface ValidatingTableProps {
  customerId: string;
  employeeResponses: DataValidationEventReportEmployeeResponse[];
}

const ValidatingTable = ({ customerId, employeeResponses }: ValidatingTableProps) => {
  const { t } = useTranslation();
  const [validationEmployeeDrawerOpen, setValidationEmployeeDrawerOpen] = useState(false);
  const [openEmployeeId, setOpenEmployeeId] = useState<string>();
  const openEmployeeResponse = employeeResponses?.find((response) => response.employee?.id === openEmployeeId);

  const head = [
    { name: t('validating:complete-tab.table.name') },
    { name: t('validating:complete-tab.table.person-number') },
    { name: t('validating:complete-tab.table.employer') },
    { name: t('validating:complete-tab.table.validation') },
    { name: t('validating:complete-tab.table.sub-action-category') },
    { name: t('validating:complete-tab.table.done') },
  ];

  const handleEmployeeDrawer = (employee: Employee) => {
    setOpenEmployeeId(employee.id);
    setValidationEmployeeDrawerOpen(true);
  };

  const employers = useFullEmployers(customerId);

  return (
    <>
      <TableBase maxHeightPercentage={45}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`ValidatingTableHead-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeResponses.map((response, index) => {
            return (
              <ValidatingTableRow
                key={`ValidatingTableRow-${response.employee.id}-${index}`}
                employee={response.employee}
                checkpoints={response.checkpoints}
                employers={employers}
                handleEmployeeDrawer={() => handleEmployeeDrawer(response.employee)}
              />
            );
          })}
        </TableBody>
      </TableBase>
      <ReportEmployeeDrawer
        open={validationEmployeeDrawerOpen}
        employee={openEmployeeResponse?.employee}
        handleClose={() => setValidationEmployeeDrawerOpen(false)}
      >
        <UpdateEmployeeTable employeeResponse={openEmployeeResponse} employers={employers} />
      </ReportEmployeeDrawer>
    </>
  );
};

export default ValidatingTable;
