import React from 'react';
import styles from './styles.module.scss';
import { Pill, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';

interface InsuranceEventLabelProps {
  checkpoints: InsuranceEventCheckpoint[];
}

function InsuranceEventLabel({ checkpoints }: InsuranceEventLabelProps) {
  const { t } = useTranslation();
  const firstCheckpoint = checkpoints.at(0);

  return (
    <div className={styles.labelContainer}>
      <Typography variant="body2" bold>
        {t(`insuranceEventTypes:${firstCheckpoint.insuranceEventType}`)}
      </Typography>
      <span className={styles.pillContainer}>
        {checkpoints.map((checkPoint) => (
          <Pill
            key={`insurance-event-type-pill-${checkPoint.benefitType}-${checkPoint.id}`}
            type={'neutral'}
            label={t(`benefitTypes:${checkPoint.benefitType}`)}
          />
        ))}
      </span>
    </div>
  );
}

export default InsuranceEventLabel;
