import { gql } from '@apollo/client';

export const GET_DATA_VALIDATION_CHECKPOINTS = gql`
  query GetDataValidationCheckpoints($employerIds: [UUID]!, $wageFilePeriod: String!) {
    reportEmployees(
      employerIds: $employerIds
      wageFilePeriod: $wageFilePeriod
      actionCategory: "DATA_VALIDATION_EVENT"
    ) {
      employee {
        id
        firstName
        lastName
        personNumber
        employmentNumber
        employerId
        employmentNumber
        dateOfBirth
        employmentStartDate
        employmentEndDate
        hasDraft
      }
      employeeCard {
        id
        employeeId
        employmentGroupId
        employmentCategory
        remunerationType
        employmentType
        workingPercentage
      }
      checkpoints {
        id
        errandId
        subActionCategory
        checkpointStatus
        additionalData {
          employeeCardId
          employeeCardPeriod
          fields {
            key
            value
            previous
            rawValue
          }
          wageTypeMappings {
            code
            description
          }
        }
      }
    }
  }
`;
