import { Button, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IndividualChoiceField from 'src/components/Employees/EmployeeOverview/EmployeeIndividualChoices/Drawer/IndividualChoiceField';
import IndividualChoiceProperty from 'src/components/Employees/EmployeeOverview/EmployeeIndividualChoices/IndividualChoiceProperty';
import { EmployeeIndividualChoice } from 'src/types/employees/EmployeeIndividualChoice';
import { UpdateEmployeeIndividualChoiceRequest } from 'src/types/employees/request/UpdateEmployeeIndividualChoiceRequest';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import { isFromPeriodBeforeEmploymentStartDate } from 'src/util/IndividualChoiceUtil';

interface IndividualChoiceInputsProps {
  individualChoice: EmployeeIndividualChoice;
  employeeId: string;
  employmentStartDate: string;
  onUpdate: (request: UpdateEmployeeIndividualChoiceRequest) => void;
}

const IndividualChoiceInputs: React.FC<IndividualChoiceInputsProps> = ({
  individualChoice,
  employeeId,
  employmentStartDate,
  onUpdate,
}) => {
  const [request, setRequest] = useState<UpdateEmployeeIndividualChoiceRequest>({
    employeeId,
    individualChoiceId: individualChoice.id,
    fromPeriod: individualChoice.fromPeriod,
    individualAge: individualChoice.individualAge,
    source: individualChoice.source,
  });

  const { t } = useTranslation();

  const handleChange = (key: string, value: string) => {
    setRequest((prev) => ({ ...prev, [key]: value }));
  };

  const invalidFromPeriod = inputSchema.validate({ period: request.fromPeriod }).error;
  const toEarlyFromPeriod = isFromPeriodBeforeEmploymentStartDate(request.fromPeriod, employmentStartDate);

  return (
    <div>
      <div className="update-individual-choice-itp1-from-date__date-header">
        <Typography variant="subtitle" bold>
          {t('employees:employmentStartDate')}
        </Typography>
        <Pill label={employmentStartDate} type="neutral" />
      </div>
      <div className="update-individual-choice-itp1-from-date__inputs">
        <ListGroup variant="inline-edit">
          <IndividualChoiceProperty property="type" individualChoice={individualChoice} isEditing />
          <IndividualChoiceProperty property="source" individualChoice={individualChoice} isEditing />
          <IndividualChoiceField
            property="fromPeriod"
            individualChoice={individualChoice}
            value={request.fromPeriod}
            onChange={handleChange}
          />
        </ListGroup>
        <Button
          type="primary"
          label={t('common:save-changes')}
          onClick={() => onUpdate(request)}
          disabled={invalidFromPeriod !== undefined || toEarlyFromPeriod}
        />
      </div>
    </div>
  );
};

export default IndividualChoiceInputs;
