import { SortDirection, TableBody, TableHead, TableRow, Td, Th } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import TableBase from 'src/components/Common/TableBase';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import { PremiumInvoiceRowSortField } from './PremiumInvoiceRowSortField';
import { PremiumInvoiceRow } from 'src/types/invoice/PremiumInvoiceRow';
import { getPremiumInvoiceTableHeads, translateBenefitType } from '../utils/premiumInvoiceRowUtils';
import { stringOrBlank } from 'src/util/StringUtil';
import { formatSweAmountText, formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';

interface PremiumInvoiceRowsTableProps {
  premiumInvoiceRows: PremiumInvoiceRow[];
}

const PremiumInvoiceRowsTable: FC<PremiumInvoiceRowsTableProps> = ({ premiumInvoiceRows }) => {
  const [sortField, setSortField] = useState<PremiumInvoiceRowSortField>('periodEnd');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));
  const handleSortFieldChange = (sortField: PremiumInvoiceRowSortField) => setSortField(sortField);
  const sortFunction = (a: PremiumInvoiceRow, b: PremiumInvoiceRow) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalAsc : sortNumericalDesc;
    switch (sortField) {
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'agreementNumber':
        return sortAlphabetical(a.insurance.agreementNumber, b.insurance.agreementNumber);
      case 'contractBenefitGroup':
        return sortAlphabetical(a.contractBenefitGroup, b.contractBenefitGroup);
      case 'contractBenefitGroupName':
        return sortAlphabetical(a.contractBenefitGroupName, b.contractBenefitGroupName);
      case 'agreedProduct':
        return sortAlphabetical(a.insurance?.agreedProduct?.name, b.insurance?.agreedProduct?.name);
      case 'insuranceNumber':
        return sortAlphabetical(a.insurance.insuranceNumber, b.insurance.insuranceNumber);
      case 'benefitType':
        return sortAlphabetical(a.insurance.benefitType, b.insurance.benefitType);
      case 'periodStart':
        return sortDate(a.periodStart, b.periodStart);
      case 'periodEnd':
        return sortDate(a.periodEnd, b.periodEnd);
      case 'dueDate':
        return sortDate(a.dueDate, b.dueDate);
      case 'amount':
      case 'invoiceNumber':
        return sortNumerical(a[sortField], b[sortField]);
      case 'note':
        return sortAlphabetical(a.note, b.note);
      default:
        return undefined;
    }
  };

  const sortedPremiumInvoiceRows = [...premiumInvoiceRows].sort(sortFunction) || [];
  const tableHeads = getPremiumInvoiceTableHeads(handleSortDirectionChange, handleSortFieldChange);
  return (
    <TableBase compressed hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns}>
      <TableHead>
        <TableRow>
          {tableHeads.map((head, index) => (
            <Th
              compressed
              includeSortButtons
              sortDirection={sortField === head.fieldName ? sortDirection : undefined}
              handleSortDirectionChange={head.handleSortDirectionChange}
              key={`PremiumInvoiceRowsTableHead-${head.title}-${index}`}
            >
              {head.title}
            </Th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedPremiumInvoiceRows.map((premiumInvoiceRow) => (
          <TableRow data-testid={`premium-invoice-rows-table-row__${premiumInvoiceRow.id}`}>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insuranceProvider.legalName)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insurance?.agreedProduct?.name)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insurance?.insuranceNumber)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insuranceTypeMapping?.name)}</Td>
            <Td alignRight compressed>
              {stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(premiumInvoiceRow.amount))}
            </Td>
            <Td alignRight compressed>
              {stringOrBlank(formatSweAmountText(premiumInvoiceRow.salaryTax))}
            </Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.periodStart)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.periodEnd)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.insurance?.agreementNumber)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.invoiceNumber)}</Td>
            <Td compressed>{translateBenefitType(premiumInvoiceRow.insuranceTypeMapping?.benefitType)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.contractBenefitGroup)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.contractBenefitGroupName)}</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow?.dueDate)}</Td>
            {/* placeholder for paymentDueDate */}
            <Td compressed>-</Td>
            <Td compressed>{stringOrBlank(premiumInvoiceRow.note)}</Td>
          </TableRow>
        ))}
      </TableBody>
    </TableBase>
  );
};

export default PremiumInvoiceRowsTable;
