import { Button, Card, CardAction, Modal, Option, Select } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TextInput from 'src/components/Common/TextInput';
import { useCreateBalancingOfBooksSettings } from 'src/service/accountingunit/BalancingOfBooksSettings';
import { Month } from 'src/types/Month';
import { CreateBalancingOfBooksSettingsInput } from 'src/types/accountingunit/BalancingOfBooksSettings';
import { convertMonthEnumToNumber, useMonthOptions } from 'src/util/date/Months';
import { getMonthDayOptionsFromMonth, toMonthDay } from 'src/util/date/PeriodUtil';

interface AddBalancingOfBooksSettingsModalProps {
  open: boolean;
  onClose: () => void;
}
const AddBalancingOfBooksSettingsModal: FC<AddBalancingOfBooksSettingsModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [dayOfMonth, setDayOfMonth] = useState<number>(31);
  const [annualStartMonth, setAnnualStartMonth] = useState<Month>('JANUARY');
  const [annualStartDay, setAnnualStartDay] = useState<number>(1);
  const [annualEndMonth, setAnnualEndMonth] = useState<Month>('DECEMBER');
  const [annualEndDay, setAnnualEndDay] = useState<number>(31);
  const createBalancingOfBooksSettings = useCreateBalancingOfBooksSettings();

  const handleDayOfMonthChange = (e: ChangeEvent<HTMLInputElement>) => setDayOfMonth(e.target.valueAsNumber);

  const monthOptions = useMonthOptions();

  const handleAnnualStartMonthChange = (option: Option<Month>) => setAnnualStartMonth(option.value);
  const handleAnnualStartDayChange = (option: Option<number>) => setAnnualStartDay(option.value);
  const handleAnnualEndMonthChange = (option: Option<Month>) => setAnnualEndMonth(option.value);
  const handleAnnualEndDayChange = (option: Option<number>) => setAnnualEndDay(option.value);

  const handleModalClose = () => {
    setAnnualStartMonth('JANUARY');
    setAnnualStartDay(1);
    setAnnualEndMonth('JANUARY');
    setAnnualEndDay(1);
    setDayOfMonth(31);
    onClose();
  };
  const handleCreateBalancingOfBookSettings = () => {
    const request: CreateBalancingOfBooksSettingsInput = {
      annualStartDate: toMonthDay(convertMonthEnumToNumber(annualStartMonth), annualStartDay),
      annualEndDate: toMonthDay(convertMonthEnumToNumber(annualEndMonth), annualEndDay),
      dayOfMonth,
    };
    createBalancingOfBooksSettings(request, employerId).then(handleModalClose);
  };

  return (
    <Modal centered overlayBackground open={open} onClose={handleModalClose}>
      <Card
        title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.title')}
        defaultClose
        onClose={handleModalClose}
      >
        <div className="accounting-settings-tab__card-content-row">
          <Select
            label={t(
              'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.annual-start-date',
            )}
            options={monthOptions}
            alphabetical={false}
            selected={monthOptions.find((option) => option.value === annualStartMonth)}
            onChange={handleAnnualStartMonthChange}
          />
          <Select
            options={getMonthDayOptionsFromMonth(annualStartMonth)}
            selected={{ label: annualStartDay.toString(), value: annualStartDay }}
            onChange={handleAnnualStartDayChange}
          />
        </div>
        <div className="accounting-settings-tab__card-content-row">
          <Select
            label={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.annual-end-date')}
            options={monthOptions}
            alphabetical={false}
            selected={monthOptions.find((option) => option.value === annualEndMonth)}
            onChange={handleAnnualEndMonthChange}
          />
          <Select
            options={getMonthDayOptionsFromMonth(annualEndMonth)}
            selected={{ label: annualEndDay.toString(), value: annualEndDay }}
            onChange={handleAnnualEndDayChange}
          />
        </div>
        <TextInput
          type="number"
          variant="default"
          label={t(
            'economySettings:accounting-settings-tab.balancing-of-books-settings-cards.modal.day-of-month-label',
          )}
          validationKey="integer"
          value={dayOfMonth.toString()}
          onChange={handleDayOfMonthChange}
        />
        <CardAction>
          <Button
            type="link"
            label={t('common:cancel')}
            onClick={handleModalClose}
            data-cy="create-policy-modal__cancel-button"
          />
          <Button
            label={t('economySettings:accounting-rows-tab.add-accounting-row-modal.add')}
            onClick={handleCreateBalancingOfBookSettings}
            data-cy="create-policy-modal__create-button"
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default AddBalancingOfBooksSettingsModal;
