import { gql } from '@apollo/client';

export const UPDATE_DRAFT_EMPLOYEE_CARD_WAGE = gql`
  mutation UpdateDraftEmployeeCardWage($request: UpdateDraftEmployeeCardWage!) {
    updateDraftEmployeeCardWage(request: $request) {
      id
      wageType
      employerWageTypeId
      payTypeName
      payTypeNumber
      amount
      currency
      fromDate
      toDate
      inUse
      payTypeName
      payTypeNumber
    }
  }
`;
