import { generateUUID } from 'src/util/UUIDUtil';

export const INSURANCE_EVENT_ID = generateUUID();
export const EMPLOYEE_ID = generateUUID();
export const EMPLOYER_ID = generateUUID();
export const INSURANCE_PROVIDER_ID = generateUUID();
export const REPORTING_EVENT_ID = generateUUID();
export const INSURANCE_ID = generateUUID();
export const EMPLOYEE_CARD_ID = generateUUID();
export const EMPLOYMENT_GROUP_ID = generateUUID();
export const POLICY_ENTITY_ID = generateUUID();
export const MESSAGE_RECEIPT_ID = generateUUID();
export const MESSAGE_ID = generateUUID();
export const ACCOUNTING_UNIT_ID = generateUUID();
export const CUSTOMER_ID = generateUUID();
export const WAGE_ID = generateUUID();
export const EMPLOYEE_INSURANCE_ID = generateUUID();
export const AGREED_PRODUCT_ID = generateUUID();
export const PREMIUM_INVOICE_ROW_ID = generateUUID();
export const ACCOUNTING_ITEM_ID = generateUUID();
export const INSURANCE_PRODUCT_ID = generateUUID();
export const CHART_OF_ACCOUNTS_ID = generateUUID();
export const EMPLOYER_WAGE_TYPE_ID = generateUUID();
export const INVOICE_ID = generateUUID();
export const EMPLOYER_INDIVIDUAL_CHOICE_ID = generateUUID();
export const PERSON_ID = generateUUID();
export const EMPLOYEE_INDIVIDUAL_CHOICE_ID = generateUUID();
export const EMPLOYER_WAGE_FILE_SETTING_ID = generateUUID();
export const INSURANCE_TYPE_MAPPING_ID = generateUUID();
export const TEMPLATE_ID = generateUUID();
export const REFERENCE_ID = generateUUID();

export const ACCOUNTING_UNIT_NAME = 'Ekonomiavdelning';
export const ACCOUNTING_UNIT_DESCRIPTION = 'Beskrivning av ekonomiavdelning';
export const CHART_OF_ACCOUNTS_NAME = 'Kontoplan';
export const CHART_OF_ACCOUNTS_DESCRIPTION = 'Beskrivning av kontoplan';
export const BENEFIT_TYPE = 'RETIREMENT_PENSION';
export const EMPLOYER_LEGAL_NAME = 'Bolaget AB';
export const CUSTOMER_NAME = 'Kund';
export const INSURANCE_PROVIDER_LEGAL_NAME = 'Svandia';
export const INSURANCE_TYPE_MAPPING_NAME = 'Försäkringstyp';
export const EMPLOYMENT_NUMBER = '24893';
export const PERSON_NUMBER = '19800303-6343';
export const FIRST_NAME = 'Mia';
export const LAST_NAME = 'Svensson';
export const PERIOD = '202307';
export const INSURANCE_EVENT_TYPE = 'NEW_REGISTRATION_DUE_TO_POLICY_SATISFIED';
export const REPORTING_EVENT_TYPE = 'NEW_REGISTRATION';
export const EMPLOYMENT_CATEGORY = 'WHITE_COLLAR';
export const REMUNERATION_TYPE = 'MONTHLY_SALARY';
export const EMPLOYMENT_TYPE = 'FULL_TIME';
export const WORKING_PERCENTAGE = 100;
export const PART_TIME_PENSION = false;
export const SICK_DAYS = 0;
export const AVAILABLE_MESSAGE_FORMAT = 'COLLECTUM_XML_3_0';
export const MESSAGE_FORMAT_CATEGORY = 'MSM_FORMAT';
export const ORGANISATION_NUMBER = '556465-3719';
export const MESSAGE_STATUS = 'SENT';
export const EVENT_FROM_DATE = '2023-07-01';
export const INSURANCE_EVENT_STATUS = 'READY_TO_REPORT';
export const EMPLOYMENT_START_DATE = '2021-07-01';
export const EMPLOYMENT_END_DATE = '2024-04-04';
export const EARLIEST_EMPLOYMENT_START_DATE = '2023-01-01';
export const PREVIOUS_EMPLOYMENT_END_DATE = '2022-04-04';
export const CUSTOMER_EMPLOYMENT_START_DATE = '2021-08-08';
export const DATE_OF_BIRTH = '1980-03-03';
export const EMPLOYEE_SOURCE_TYPE = 'MIGRATION';
export const WAGE_FILE_FORMAT = 'XML';
export const EXPECTED_NUMBER_OF_WAGE_FILES = 1;
export const EMPLOYMENT_GROUP_NAME = 'Anställningsgrupp';
export const WAGE_TYPE = 'GROSS_SALARY';
export const EMPLOYER_WAGE_TYPE = '70';
export const FROM_DATE = '2021-12-01';
export const WAGE_AMOUNT = 40000;
export const IN_USE = false;
export const INSURANCE_NAME = 'Min försäkring';
export const INSURANCE_NUMBER = '4444';
export const INSURANCE_STATUS = 'ACTIVE_CONFIRMED';
export const INSURANCE_STATUS_CATEGORY = 'ACTIVE';
export const INSURANCE_LATEST_STATUS_CHANGE = '2021-05-10T10:10:10';
export const AGREEMENT_NUMBER = '123456';
export const AGREED_PRODUCT_NAME = 'Min avtalade försäkringsprodukt';
export const PERIOD_START = new Date('2022-01-01');
export const PERIOD_END = new Date('2023-02-02');
export const DUE_DATE = new Date('2023-01-11');
export const INVOICE_NUMBER = '4';
export const OCR_NUMBER = '1234';
export const INVOICE_STATUS = 'PREPARED_FOR_PAYMENTS';
export const INSURANCE_PROVIDER_ORGNR = '1234567890';
export const INSURANCE_PROVIDER_NAME = 'Collectum';
export const ACCOUNT_NUMBER_TO = '54321';
export const SALARY_TAX = 1000;
export const SALARY_TAX_STRING = '1 000';
export const CONTRACT_BENEFIT_GROUP = '2';
export const CONTRACT_BENEFIT_GROUP_NAME = 'Grupp 2';
export const AMOUNT = 2000;
export const AMOUNT_STRING = '2 000';
export const NOTE = 'Kommentar';
export const STATUS = 'PRELIMINARY';
export const ACCOUNTING_NUMBER = '1234';
export const ACCOUNTING_COMPONENT_NAME = 'IT';
export const ACCOUNTING_TYPE_VALUE = 'RESULT_ACCOUNT';
export const ACCOUNTING_PERIOD = '202305';
export const PREMIUM_PERIOD = '202304';
export const INSURANCE_PRODUCT_NAME = 'Min försäkringsprodukt';
export const CERTIFY_INSURANCE_PRODUCT_CONDITION = true;
export const WAGE_FILE_PERIOD = '202103';
export const ABSENCE_WAGE_TYPE = 'LEAVE_OF_ABSENCE_SICK';
export const PAY_TYPE_NAME = 'Löneart';
export const PAY_TYPE_NUMBER = '123';
export const TO_DATE = '2021-12-31';
export const EXTENT_ADJUSTED_ABSENCE_DAYS = 10;
export const INVOICE_TYPE_CODE = 'DEBIT';
export const MESSAGE_RECEIPT_FILE_NAME = 'file.file';
export const MESSAGE_TRANSFER_CHANNEL = 'SSEK';
export const MESSAGE_SENT_AT = new Date('2023-02-02').toISOString();
export const EMPLOYER_INDIVIDUAL_CHOICE_TYPE = 'ITP1';
export const FROM_WAGE_FILE = true;
export const FROM_INVOICE = true;
export const INDIVIDUAL_CHOICE_TYPE = 'ITP1';
export const INDIVIDUAL_CHOICE_SOURCE = 'MANUAL';
export const DOCUMENT_FORMAT_TYPE = 'PDF';
export const TEMPLATE_NAME = 'Min dokumentmall';
export const TEMPLATE_DESCRIPTION = 'Mallbeskrivning';
export const INPUT_PERIOD_PARAMETER = 'PERIOD_SINGLE_YEAR_MONTH';
export const CREATED_DATE = '2023-10-10';
export const FILE_NAME = 'fileName.pdf';
export const FROM_PERIOD = '202401';
export const TO_PERIOD = '202402';
export const LATEST_DOWNLOAD_DATE = '2023-10-10';
export const DEFAULT_PAGE_SIZE = 20;
