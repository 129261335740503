import { Button, Icon, ListGroup, DrawerNavigation } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import AbsenceEmployerWageTypeLabel from './AbsenceEmployerWageTypeLabel';
import './absence-wage-types-overview-drawer.scss';
import EmployeeInDraftBanner from 'src/components/Common/EmployeeInDraftBanner';

interface AbsenceWageTypesOverviewDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  onSelectAbsenceWageType: (wageId: string) => void;
  displayCreateDraftAbsenceWageDrawer: () => void;
  aggregatedAbsenceEmployeeCards: AggregatedEmployeeCard[];
  employerId: string;
  selectedPeriod: string;
  isDraft: boolean;
  employeeCardId: string;
}
export const AbsenceWageTypesOverviewDrawer: FC<AbsenceWageTypesOverviewDrawerProps> = ({
  onClose,
  onBackClick,
  onSelectAbsenceWageType,
  aggregatedAbsenceEmployeeCards,
  employerId,
  selectedPeriod,
  isDraft,
  displayCreateDraftAbsenceWageDrawer,
}) => {
  const { t } = useTranslation();

  const formattedPeriod = getFormattedDateStringFromPeriod(selectedPeriod);
  const currentPeriodEmployeeCard = aggregatedAbsenceEmployeeCards.find((s) => s.period == selectedPeriod);
  const employerWageTypes = useEmployerWageTypes(employerId);

  return (
    <DrawerBase
      title={t('wages:absence-wage-types')}
      subTitle={formattedPeriod}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <div className="absence-wage-types-overview-drawer">
        {isDraft && <EmployeeInDraftBanner />}
        <ListGroup variant="drawer-navigation">
          {currentPeriodEmployeeCard?.aggregatedWageTypeWages?.map((aggregatedWage) => {
            const hasWagesChangedInDraft = aggregatedWage.wages?.some((w) => w.isDraftModified);
            return (
              <DrawerNavigation
                label={
                  <AbsenceEmployerWageTypeLabel
                    employerWageTypes={employerWageTypes}
                    employerWageTypeId={aggregatedWage.employerWageTypeId}
                    hasWagesChangedInDraft={hasWagesChangedInDraft}
                  />
                }
                value={`${aggregatedWage.extentAdjustedAbsenceDays} ${
                  aggregatedWage.extentAdjustedAbsenceDays === 1
                    ? t('wages:extent-adjusted-absence-days-singular')
                    : t('wages:extent-adjusted-absence-days-plural')
                }`}
                onClick={() => onSelectAbsenceWageType(aggregatedWage.employerWageTypeId)}
                key={aggregatedWage.employerWageTypeId}
              />
            );
          })}
        </ListGroup>
        {isDraft && (
          <ListGroup variant="drawer-navigation">
            <DrawerNavigation label={t('wages:add-absence-wage')} onClick={displayCreateDraftAbsenceWageDrawer} />
          </ListGroup>
        )}
      </div>
    </DrawerBase>
  );
};
export default AbsenceWageTypesOverviewDrawer;
