import React, { useEffect } from 'react';
import { Button, Divider, InlineEdit, ListGroup, Option, Select } from 'fgirot-k2-ui-components';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import { useTranslation } from 'react-i18next';
import { useEmployerPayTypes } from 'src/service/employer/GetEmployerPayTypes';
import { globalAbsenceWageTypes, payTypeIsCorrectlyMapped, payTypeIsOnlyMappedToKUB } from 'src/util/WageUtil';
import { CreateDraftEmployeeCardWageRequest } from 'src/types/employees/request/CreateDraftEmployeeCardWageRequest';
import { parseSweAmountTextWithNegativeAmounts } from 'src/util/Number/AmountFormatter';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import InfoMessage from 'src/components/Common/InfoMessage';
import TextInput from 'src/components/Common/TextInput';
import { stringOrBlank } from 'src/util/StringUtil';
import { AmountInput, PayedAmountInput } from './index';
import When from 'src/components/Common/When';
import { WageType } from 'src/types/employer/EmployerWageType';
import { CreateWageRequest } from 'src/types/CreateWageRequest';
import EmployeeInDraftBanner from '../EmployeeInDraftBanner';

interface CreateWageProps {
  employerId: string;
  employeeCardId: string;
  isDraft?: boolean;
  availableWageTypes?: WageType[];
  onCreate: (request: CreateWageRequest) => Promise<void>;
}

function CreateWage({ employerId, onCreate, employeeCardId, isDraft = true, availableWageTypes }: CreateWageProps) {
  const [selectedEmployerPayType, setSelectedEmployerPayType] = React.useState<Option<EmployerPayType>>();
  const [amount, setAmount] = React.useState<string>('');
  const [payedAmount, setPayedAmount] = React.useState<string>('0');
  const [createButtonDisabled, setCreateButtonDisabled] = React.useState(false);

  const { t } = useTranslation();

  const employerPayTypes = useEmployerPayTypes(employerId);

  const shouldBeAbleToAddPayedAmount = selectedEmployerPayType?.value?.employerKubs.length > 0;

  const filterEmployerPayTypes = (payType: EmployerPayType) => {
    if (globalAbsenceWageTypes.includes(payType.employerWageType?.wageType)) return false;
    if (availableWageTypes == null) return true;
    return availableWageTypes.includes(payType.employerWageType?.wageType);
  };

  const employerPayTypeOptions: Option<EmployerPayType>[] = employerPayTypes
    .filter(filterEmployerPayTypes)
    .filter((payType) => payType.inUse)
    .filter((payType) => payTypeIsCorrectlyMapped(payType))
    .map((payType) => ({
      label: `${payType.number} - ${payType.name}`,
      value: payType,
    }));

  const isPayTypeOnlyMappedToKub = payTypeIsOnlyMappedToKUB(selectedEmployerPayType?.value);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handlePayedAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayedAmount(e.target.value);
    if (isPayTypeOnlyMappedToKub) {
      setAmount(e.target.value);
    }
  };

  const handleCreateWage = async () => {
    setCreateButtonDisabled(true);

    const request: CreateDraftEmployeeCardWageRequest = {
      employeeCardId,
      payTypeNumber: selectedEmployerPayType.value.number,
      amount: +parseSweAmountTextWithNegativeAmounts(amount),
    };

    if (shouldBeAbleToAddPayedAmount) {
      request.payedAmount = +parseSweAmountTextWithNegativeAmounts(payedAmount);
    }

    await onCreate(request);
    setCreateButtonDisabled(false);
  };

  useEffect(() => {
    const amountValidation = inputSchema.validate({
      amount2DecAllowNegative: amount,
    });
    const payedAmountValidation = inputSchema.validate({
      amount2DecAllowNegative: payedAmount,
    });

    if (!selectedEmployerPayType || !amount || !payedAmount || amountValidation.error || payedAmountValidation.error) {
      setCreateButtonDisabled(true);
    } else {
      setCreateButtonDisabled(false);
    }
  }, [amount, payedAmount, selectedEmployerPayType]);

  return (
    <div className="wage-details-drawer">
      <When condition={isDraft}>
        <EmployeeInDraftBanner />
        <InfoMessage message={t('wages:absence-wages-cannot-be-added')} />
      </When>
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('wages:pay-type')} bold>
          <Select
            options={employerPayTypeOptions}
            onChange={(option) => setSelectedEmployerPayType(option)}
            selected={selectedEmployerPayType}
            placeholder={t('wages:select-pay-type')}
            small
            data-cy="pay-type-select"
          />
        </InlineEdit>
        <When condition={!isPayTypeOnlyMappedToKub}>
          <InlineEdit label={t('wages:wage-type')} bold>
            <TextInput value={stringOrBlank(selectedEmployerPayType?.value?.employerWageType?.name)} disabled />
          </InlineEdit>
          <Divider />
          <AmountInput isEditable amount={amount} handleAmountChange={handleAmountChange} />
        </When>
        <When condition={shouldBeAbleToAddPayedAmount}>
          <PayedAmountInput isEditable payedAmount={payedAmount} handlePayedAmountChange={handlePayedAmountChange} />
        </When>
      </ListGroup>
      <When condition={shouldBeAbleToAddPayedAmount}>
        <InfoMessage
          message={`${t('wages:payed-amount-info')} ${selectedEmployerPayType?.value?.employerKubs
            .map((kub) => kub.kubType)
            .join(', ')}`}
        />
      </When>
      <Button
        type="primary"
        label={t('wages:add-wage')}
        onClick={handleCreateWage}
        disabled={createButtonDisabled}
        data-cy="add-wage-button"
      />
    </div>
  );
}

export default CreateWage;
