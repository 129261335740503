import { Typography } from 'fgirot-k2-ui-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterButton from 'src/components/Common/FilterButton';
import When from 'src/components/Common/When';
import {
  SearchPremiumInvoiceRowsFilters,
  SearchPremiumInvoiceRowsRequest,
} from 'src/types/invoice/SearchPremiumInvoiceRowsRequest';
import './premium-invoice-rows-filter-details.scss';

interface PremiumInvoiceRowsFilterDetailsProps {
  searchRequest: SearchPremiumInvoiceRowsRequest;
  applyFilters: (filters: SearchPremiumInvoiceRowsFilters) => void;
}

export const PremiumInvoiceRowsFilterDetails: FC<PremiumInvoiceRowsFilterDetailsProps> = ({
  searchRequest,
  applyFilters,
}) => {
  const { t } = useTranslation();
  const hasInsuranceProviderFilters = searchRequest.filters?.insuranceProviderIdsFilter?.length > 0;
  const hasPeriodStartFilter = !!searchRequest.filters?.periodFilter?.periodStart;
  const hasPeriodEndFilter = !!searchRequest.filters?.periodFilter?.periodEnd;

  const removeInsuranceProviderFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      insuranceProviderIdsFilter: null,
    });
  };

  const removePeriodStartFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      periodFilter: {
        ...searchRequest.filters.periodFilter,
        periodStart: null,
      },
    });
  };

  const removePeriodEndFilter = () => {
    applyFilters({
      ...searchRequest.filters,
      periodFilter: {
        ...searchRequest.filters.periodFilter,
        periodEnd: null,
      },
    });
  };

  return (
    <div className="premium-invoice-rows-filter-details">
      <Typography variant="caption" bold>
        Filter:
      </Typography>
      <When condition={!hasInsuranceProviderFilters && !hasPeriodStartFilter && !hasPeriodEndFilter}>
        <Typography variant="caption" italic>
          Inga filter
        </Typography>
      </When>
      <When condition={hasInsuranceProviderFilters}>
        <FilterButton
          label={
            searchRequest.filters?.insuranceProviderIdsFilter?.length +
            ' ' +
            t('premiumInvoiceRows:filters.insurance-provider-filter')
          }
          onClick={removeInsuranceProviderFilter}
        />
      </When>
      <When condition={hasPeriodStartFilter}>
        <FilterButton
          label={
            searchRequest.filters?.periodFilter?.periodStart + ' ' + t('premiumInvoiceRows:filters.period-start-filter')
          }
          onClick={removePeriodStartFilter}
        />
      </When>
      <When condition={hasPeriodEndFilter}>
        <FilterButton
          label={
            searchRequest.filters?.periodFilter?.periodEnd + ' ' + t('premiumInvoiceRows:filters.period-end-filter')
          }
          onClick={removePeriodEndFilter}
        />
      </When>
    </div>
  );
};

export default PremiumInvoiceRowsFilterDetails;
