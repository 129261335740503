import { gql } from '@apollo/client';

export const GET_POLICY_ENTITIES = gql`
  query GetPolicyEntities($employerId: UUID!) {
    policyEntities(employerId: $employerId) {
      id
      fromDate
      toDate
      state
      version
      employerId
      policyComments {
        id
        createdBy
        createdDate
        comment
      }
    }
  }
`;
