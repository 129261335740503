import { SearchInvoicesRequest } from 'src/types/invoice/SearchInvoicesRequest';

export const getInitialSearchInvoicesRequest = (): SearchInvoicesRequest => {
  return {
    searchInput: '',
    pageNumber: 0,
    pageSize: 25,
    filters: {
      paymentDueDateFilter: '',
      statusFilter: null,
      insuranceProviderIdsFilter: null,
      invoiceTypeCodesFilter: null,
    },
  };
};
