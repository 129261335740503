export interface PolicyEntity {
  id: string;
  version: string;
  fromDate: string;
  toDate: string;
  state: PolicyState;
  employerId: string;
  policyComments: PolicyComment[];
}

export interface PolicyComment {
  id: string;
  policyId?: string;
  createdBy: string;
  createdDate: string;
  comment: string;
}

export type PolicyState = 'DRAFT' | 'PENDING' | 'ACTIVE' | 'INACTIVE';

export const DRAFT: PolicyState = 'DRAFT';
export const PENDING: PolicyState = 'PENDING';
export const ACTIVE: PolicyState = 'ACTIVE';
export const INACTIVE: PolicyState = 'INACTIVE';
