import { SearchInvoiceRowRequest, SearchInvoiceRowsFilters } from 'src/types/invoice/SearchInvoiceRowsRequest';
import { getInitialSearchInvoiceRowRequest } from '../Utils/invoiceRowUtils';
import { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { SEARCH_INVOICE_ROWS } from 'src/graphql/schema/query/invoice/SearchInvoiceRows';
import InvoiceRows from './InvoiceRows/InvoiceRows';
import { SearchInvoiceRowsPage } from 'src/types/invoice/InvoiceRow';
import { useParams } from 'react-router-dom';
import './invoice-row-overview.scss';

const InvoiceRowOverview = () => {
  const [searchInvoiceRowsRequest, setSearchInvoiceRowsRequest] = useState<SearchInvoiceRowRequest>(
    getInitialSearchInvoiceRowRequest(),
  );
  const { invoiceId } = useParams();

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  const handleTextSearch = (searchInput: string) => {
    setSearchInvoiceRowsRequest({
      ...searchInvoiceRowsRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchInvoiceRowsFilters) => {
    setSearchInvoiceRowsRequest({ ...searchInvoiceRowsRequest, filters });
  };

  return (
    <QueryWrapper
      query={SEARCH_INVOICE_ROWS}
      options={{
        variables: {
          invoiceHeaderId: invoiceId,
          request: {
            searchInput: searchInvoiceRowsRequest?.searchInput,
            pageNumber: pageNumber,
            pageSize: pageSize,
            filters: searchInvoiceRowsRequest?.filters,
          },
        },
      }}
    >
      {({ searchInvoiceRows, paging }: SearchInvoiceRowsPage) => (
        <InvoiceRows
          invoiceRows={searchInvoiceRows}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchInvoiceRowRequest={searchInvoiceRowsRequest}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onPageChange={handlePageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default InvoiceRowOverview;
