import Joi from 'joi';

export const inputSchema = Joi.object({
  personNumber: Joi.string().allow(null, '').pattern(new RegExp('^\\d{8}-\\d{4}$')),
  expisoft: Joi.string().allow(null, '').min(1),
  percent: Joi.number().integer().allow(null, '').max(100).min(0),
  amount: Joi.number().allow(null, '').max(1000000000).min(-1000000000),
  amount2Dec: Joi.string().allow(null, '').pattern(new RegExp('^([0-9\\s]{1,11}((\\,)[0-9]{0,2})?)$')),
  amount2DecAllowNegative: Joi.string().allow(null, '').pattern(new RegExp('^(-?[0-9\\s]{1,11}((\\,)[0-9]{0,2})?)$')),
  amount4Dec: Joi.string().allow(null, '').pattern(new RegExp('^([0-9\\s]{1,11}((\\,)[0-9]{0,4})?)$')),
  age: Joi.number().allow(null).integer().max(80).min(15),
  days: Joi.number().integer().max(600).min(0),
  months: Joi.number().integer().max(12).min(0),
  text3: Joi.string().allow(null, '').max(3),
  text10: Joi.string().allow(null, '').max(10),
  text16: Joi.string().allow(null, '').max(16),
  text32: Joi.string().allow(null, '').max(32),
  text64: Joi.string().allow(null, '').max(64),
  text128: Joi.string().allow(null, '').max(128),
  text255: Joi.string().allow(null, '').max(255),
  text1000: Joi.string().allow(null, '').max(1000),
  integer: Joi.number().integer().allow(null, ''),
  positiveInteger: Joi.number().integer().positive().allow(null, '', 0).max(1000000000),
  decimal: Joi.number().allow(null, '').max(100).min(1),
  percentage: Joi.number().integer().max(100).min(0),
  dayOfMonth: Joi.number().max(31).min(1),
  dayOfMonthMax28OrNull: Joi.number().max(28).min(1).allow(null, ''),
  hourWithMinutes: Joi.string().pattern(new RegExp('^([0-1][0-9]|2[0-3]):[0-5][0-9]$')),
  yearAndMonth: Joi.string()
    .allow(null, '')
    .pattern(/^(20\d{2}|21[0-9]{2})-(0[1-9]|1[0-2])$/),
  individualRetirementAge: Joi.number().integer().max(99).min(55),
  period: Joi.string().pattern(/^(20\d{2}|21[0-9]{2})(0[1-9]|1[0-2])$/),
  phoneNumber: Joi.string()
    .allow(null, '')
    .pattern(/^(\d+|-?)$/)
    .max(16),
  email: Joi.string().allow(null, '').pattern(new RegExp('^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,})$')),
});
