import { InlineEdit, Pill, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';

interface PayedAmountInputProps {
  isEditable: boolean;
  payedAmount: string;
  handlePayedAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function PayedAmountInput({ isEditable, payedAmount, handlePayedAmountChange }: PayedAmountInputProps) {
  const { t } = useTranslation();

  return (
    <InlineEdit
      label={
        <span className="wage-details__label">
          <Typography variant="body2" bold className="wage-details__label--text">
            {t('wages:payed-amount')}
          </Typography>
          <Pill type="heads-up" label={t('wages:affects-KUB')} size="small" />
        </span>
      }
      bold
    >
      {isEditable ? (
        <TextInput
          value={payedAmount ? payedAmount.toString() : ''}
          onChange={handlePayedAmountChange}
          validationKey="amount2DecAllowNegative"
          type="text"
          data-cy="payedAmount-input"
        />
      ) : (
        <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(payedAmount)}</Typography>
      )}
    </InlineEdit>
  );
}

export default PayedAmountInput;
