import { gql } from '@apollo/client';

export const ACCOUNTING_UNITS = gql`
  query GetAccountingUnits {
    accountingUnits {
      id
      name
      description
      defaultAccountingUnit
    }
  }
`;
