import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import './update-end-date.scss';
import { useUpdateEmployee } from 'src/service/employee/UpdateEmployeeMutation';
import { Employee } from 'src/types/Employee';

interface UpdateEndDateProps {
  endDate: string;
  employee: Employee;
}

const UpdateEndDate = ({ endDate, employee }: UpdateEndDateProps) => {
  const { t } = useTranslation();
  const updateEmployee = useUpdateEmployee();
  const [lastChosenDate, setLastChosenDate] = useState(endDate);
  const handleEmployeeUpdate = (field: string, value: string) => {
    if (lastChosenDate !== value) {
      updateEmployee(
        {
          id: employee.id,
          fieldKey: field,
          fieldValue: value,
        },
        employee.employerId,
      );
    }
    setLastChosenDate(value);
  };

  return (
    <ListGroup variant="inline-edit">
      <InlineEdit bold label={t('examination:violation-types.employmentEndDate')}>
        <UpdateDatePicker fieldKey="employmentEndDate" onBlur={handleEmployeeUpdate} fieldValue={endDate} />
      </InlineEdit>
    </ListGroup>
  );
};

export default UpdateEndDate;
