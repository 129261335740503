import React, { ChangeEvent, FC, useState } from 'react';
import AnnualBalancingOfBooksDates from './AnnualBalancingOfBooksDates';
import { Card, Switch, Typography } from 'fgirot-k2-ui-components';
import TextInput from 'src/components/Common/TextInput';
import { useTranslation } from 'react-i18next';
import {
  BalancingOfBooksSettings,
  UpdateBalancingOfBooksSettingsInput,
} from 'src/types/accountingunit/BalancingOfBooksSettings';
import { useUpdateBalancingOfBooksSettings } from 'src/service/accountingunit/BalancingOfBooksSettings';
import { useParams } from 'react-router-dom';
interface BalancingOfBooksSettingsCardProps {
  balancingOfBooksSettings: BalancingOfBooksSettings;
}
const BalancingOfBooksSettingsCard: FC<BalancingOfBooksSettingsCardProps> = ({ balancingOfBooksSettings }) => {
  const { t } = useTranslation();
  const { employerId } = useParams();
  const [dayOfMonth, setDayOfMonth] = useState(balancingOfBooksSettings?.dayOfMonth);
  const updateBalancingOfBooksSettings = useUpdateBalancingOfBooksSettings();

  const hanndleUseMonthlyAdjustmentChange = (e: ChangeEvent<HTMLInputElement>) => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId: balancingOfBooksSettings.id,
      useMonthlyAdjustments: e.target.checked,
    };
    updateBalancingOfBooksSettings(request, employerId);
  };

  const handleDayOfMonthChange = (e: ChangeEvent<HTMLInputElement>) => setDayOfMonth(e.target.valueAsNumber);
  const handleDayOfMonthUpdate = () => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId: balancingOfBooksSettings.id,
      dayOfMonth,
    };
    updateBalancingOfBooksSettings(request, employerId);
  };
  return (
    <Card
      title={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.settings-title-active')}
      className="accounting-settings-tab__card-content"
    >
      <AnnualBalancingOfBooksDates
        balancingOfBooksSettingsId={balancingOfBooksSettings.id}
        annualStartDate={balancingOfBooksSettings.annualStartDate}
        annualEndDate={balancingOfBooksSettings.annualEndDate}
      />
      <div className="accounting-settings-tab__card-content-row">
        <TextInput
          className="accounting-settings-tab__text-input"
          label={t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.day-of-month')}
          data-cy="day-of-month-input"
          variant="default"
          type="number"
          value={dayOfMonth.toString()}
          validationKey="dayOfMonth"
          onChange={handleDayOfMonthChange}
          onBlur={handleDayOfMonthUpdate}
        />
      </div>
      <div className="accounting-settings-tab__card-content-row">
        <Typography variant="caption" className="accounting-settings-tab__card-content-label">
          {t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.use-monthly-adjustments')}
        </Typography>
        <Switch
          data-cy="use-monthly-adjustments-switch"
          toggled={balancingOfBooksSettings.useMonthlyAdjustments}
          onChange={hanndleUseMonthlyAdjustmentChange}
        />
      </div>
    </Card>
  );
};

export default BalancingOfBooksSettingsCard;
