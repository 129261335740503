import React, { FC, useMemo, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import InvoicesFilterDetails from './InvoiceRowsFilterDetails';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { InvoiceRow } from 'src/types/invoice/InvoiceRow';
import { SearchInvoiceRowRequest, SearchInvoiceRowsFilters } from 'src/types/invoice/SearchInvoiceRowsRequest';
import InvoiceRowsTable from './InvoiceRowTable/InvoiceRowsTable';
import SearchInvoiceRowsFiltersModal from './SearchInvoiceRowsFiltersModal';
import InvoiceRowsSearchInput from './InvoiceRowsSearchInput';
import { createPageSizeOptions } from 'src/util/OptionUtil';

interface InvoiceRowsProps {
  invoiceRows: InvoiceRow[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchInvoiceRowRequest: SearchInvoiceRowRequest;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchInvoiceRowsFilters) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const InvoiceRows: FC<InvoiceRowsProps> = ({
  invoiceRows,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  searchInvoiceRowRequest,
  onTextSearch,
  onApplyFilters,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );

  const showPagination = totalPages > 0;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  return (
    <>
      <div className="invoice-rows-overview">
        <InvoiceRowsSearchInput onSearch={onTextSearch} searchRequest={searchInvoiceRowRequest} />
        <InvoicesFilterDetails searchRequest={searchInvoiceRowRequest} applyFilters={onApplyFilters} />
      </div>
      <InvoiceRowsTable invoiceRows={invoiceRows} />
      {filtersModalOpen && (
        <SearchInvoiceRowsFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={searchInvoiceRowRequest?.filters}
        />
      )}
      {showPagination && (
        <div data-testid="invoice-rows-overview-tab-pagination" className="invoice-rows-overview__pagination">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            pageSizeLabel={t('common:results-per-page')}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={setPageSize}
            pageSizePosition="left"
          />
        </div>
      )}
      <div className="invoice-rows-overview__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-cy="filter-button"
        />
      </div>
    </>
  );
};

export default InvoiceRows;
