import { Typography, Select, Option } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUpdateBalancingOfBooksSettings } from 'src/service/accountingunit/BalancingOfBooksSettings';
import { MonthDay } from 'src/types/DayMonth';
import { Month } from 'src/types/Month';
import { UpdateBalancingOfBooksSettingsInput } from 'src/types/accountingunit/BalancingOfBooksSettings';
import { convertMonthEnumToNumber, useMonthOptions } from 'src/util/date/Months';
import { getDay, getMonth, getMonthDayOptionsFromMonth, toMonthDay } from 'src/util/date/PeriodUtil';
interface AnnualBalancingOfBooksDatesProps {
  balancingOfBooksSettingsId: string;
  annualStartDate: MonthDay;
  annualEndDate: MonthDay;
}
const AnnualBalancingOfBooksDates: FC<AnnualBalancingOfBooksDatesProps> = ({
  balancingOfBooksSettingsId,
  annualStartDate,
  annualEndDate,
}) => {
  const { employerId } = useParams();
  const { t } = useTranslation();
  const startDay = getDay(annualStartDate);
  const startMonth = getMonth(annualStartDate);
  const endDay = getDay(annualEndDate);
  const endMonth = getMonth(annualEndDate);
  const updateBalancingOfBooksSettings = useUpdateBalancingOfBooksSettings();

  const monthOptions = useMonthOptions();
  const startDayOptions = getMonthDayOptionsFromMonth(startMonth);
  const endDayOptions = getMonthDayOptionsFromMonth(endMonth);

  const handleStartDayChange = (option: Option<number>) => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId,
      annualStartDate: toMonthDay(convertMonthEnumToNumber(getMonth(annualStartDate)), option.value),
    };

    updateBalancingOfBooksSettings(request, employerId);
  };
  const handleStartMonthChange = (option: Option<Month>) => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId,
      annualStartDate: toMonthDay(convertMonthEnumToNumber(option.value), getDay(annualStartDate)),
    };
    updateBalancingOfBooksSettings(request, employerId);
  };
  const handleEndDayChange = (option: Option<number>) => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId,
      annualEndDate: toMonthDay(convertMonthEnumToNumber(getMonth(annualEndDate)), option.value),
    };
    updateBalancingOfBooksSettings(request, employerId);
  };
  const handleEndMonthChange = (option: Option<Month>) => {
    const request: UpdateBalancingOfBooksSettingsInput = {
      balancingOfBooksSettingsId,
      annualEndDate: toMonthDay(convertMonthEnumToNumber(option.value), getDay(annualEndDate)),
    };
    updateBalancingOfBooksSettings(request, employerId);
  };

  return (
    <div className="accounting-settings-tab__card-content-row">
      <Typography variant="h3" className="accounting-settings-tab__card-content-label">
        {t('economySettings:accounting-settings-tab.balancing-of-books-settings-cards.financial-year')}
      </Typography>
      <Select
        data-cy="annual-start-day-select"
        options={startDayOptions}
        selected={startDayOptions.find((option) => option.value === startDay)}
        onChange={handleStartDayChange}
      />
      <Select
        data-cy="annual-start-month-select"
        options={monthOptions}
        selected={monthOptions.find((option) => option.value === startMonth)}
        alphabetical={false}
        onChange={handleStartMonthChange}
      />
      <Typography bold>-</Typography>
      <Select
        data-cy="annual-end-day-select"
        options={endDayOptions}
        selected={endDayOptions.find((option) => option.value === endDay)}
        onChange={handleEndDayChange}
      />
      <Select
        data-cy="annual-end-month-select"
        options={monthOptions}
        alphabetical={false}
        selected={monthOptions.find((option) => option.value === endMonth)}
        onChange={handleEndMonthChange}
      />
    </div>
  );
};

export default AnnualBalancingOfBooksDates;
