import { InlineEdit, ListGroup } from 'fgirot-k2-ui-components';
import InfoMessage from 'src/components/Common/InfoMessage';
import When from 'src/components/Common/When';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import React from 'react';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import { usePatchInsuranceEvent } from 'src/service/reporting/PatchInsuranceEvent';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InsuranceEventLabel from '../InsuranceEventLabel';
import { groupByInsuranceEvent } from '../../../utils';

interface MandatoryFieldEventFromDateCheckpointProps {
  checkpoints: InsuranceEventCheckpoint[];
  insuranceEvents: Map<string, InsuranceEvent>;
  employerId: string;
}
const MandatoryFieldEventFromDateCheckpoint: React.FC<MandatoryFieldEventFromDateCheckpointProps> = ({
  employerId,
  checkpoints,
  insuranceEvents,
}) => {
  const { year, month } = useParams();
  const { t } = useTranslation();

  const updateInsuranceEvent = usePatchInsuranceEvent();
  const insuranceEventTypeToCheckpointsMap = groupByInsuranceEvent(checkpoints);
  const containsBackFromSickEvent = checkpoints.some((c) => c.insuranceEventType === 'BACK_FROM_SICK_LEAVE');

  return (
    <ListGroup variant={'inline-edit'}>
      {Array.from(insuranceEventTypeToCheckpointsMap.keys()).map((key, idx) => {
        const insuranceEventTypeCheckpoint = insuranceEventTypeToCheckpointsMap.get(key);
        const firstCheckpoint = insuranceEventTypeCheckpoint.at(0);
        const date = insuranceEvents.get(firstCheckpoint.additionalData.insuranceEventId)?.eventFromDate;
        return (
          <InlineEdit
            key={`${key}-${idx}`}
            bold
            label={<InsuranceEventLabel checkpoints={insuranceEventTypeCheckpoint} />}
          >
            <UpdateDatePicker
              fieldKey={'fromDate'}
              initialYear={year && parseInt(year)}
              initialMonth={month && parseInt(month)}
              onBlur={(_, value) => {
                insuranceEventTypeCheckpoint.forEach((eventType) =>
                  updateInsuranceEvent(employerId, eventType.additionalData.insuranceEventId, value),
                );
              }}
              fieldValue={date ?? ''}
            />
          </InlineEdit>
        );
      })}
      <When condition={containsBackFromSickEvent}>
        <InfoMessage message={t('verify:drawer.mandatory-field-from-date-info-additional-pgls')} />
      </When>
      <InfoMessage message={t('verify:drawer.mandatory-field-from-date-info-valid-dates')} />
    </ListGroup>
  );
};

export default MandatoryFieldEventFromDateCheckpoint;
