import { Icon, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import './update-employee-table.scss';
import EmployeeTableRow from './EmployeeTableRow';
import { useUpdateEmployee } from 'src/service/employee/UpdateEmployeeMutation';
import { useUpdateEmployeeCardCheckpoints } from 'src/service/employeecard/UpdateEmployeeCardCheckpoints';
import { DataValidationEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import { useTranslation } from 'react-i18next';
import { Employer } from 'src/types/employer/Employer';
import When from 'src/components/Common/When';

interface UpdateEmployeeTableProps {
  employeeResponse: DataValidationEventReportEmployeeResponse;
  employers: Employer[];
}

const UpdateEmployeeTable: FC<UpdateEmployeeTableProps> = ({
  employeeResponse: { employee, employeeCard, checkpoints },
  employers,
}) => {
  const { t } = useTranslation();
  const updateEmployeeCardMutation = useUpdateEmployeeCardCheckpoints();
  const updateEmployee = useUpdateEmployee();
  const employeeCheckpoints = checkpoints.filter(
    (checkpoint) => checkpoint.subActionCategory === 'MANDATORY_EMPLOYEE_STORAGE_DATA',
  );
  const cardCheckpoints = checkpoints.filter(
    (checkpoint) => checkpoint.subActionCategory === 'MANDATORY_EMPLOYEE_CARD_DATA',
  );
  const payTypeMappings = checkpoints.find(
    (checkpoint) => checkpoint.subActionCategory === 'MANDATORY_WAGE_TYPE_MAPPING',
  );
  const unfinishedReporting = checkpoints.find(
    (checkpoint) => checkpoint.subActionCategory === 'UNFINISHED_REPORTING_PREVIOUS_PERIOD',
  );

  const handleEmployeeUpdate = (field: string, value: string) => {
    updateEmployee(
      {
        id: employee.id,
        fieldKey: field,
        fieldValue: value.trim(),
      },
      employee.employerId,
    );
  };

  const handleEmployeeCardUpdate = (field: string, value: string) => {
    updateEmployeeCardMutation(
      {
        id: employeeCard.id,
        fieldKey: field,
        fieldValue: value,
      },
      employee.employerId,
    );
  };

  return (
    <div className={['update-employee-table'].join(' ')}>
      <div className="update-employee-table__title">
        <Icon className="update-employee-table__title__icon" type="Alert" />
        <Typography bold variant="subtitle">
          {t('validating:complete-tab.table.validations')}
        </Typography>
      </div>
      <ListGroup variant="inline-edit" className={'update-employee-table__table'}>
        <>
          {employeeCheckpoints.flatMap((checkpoint) =>
            checkpoint.additionalData.fields.map((field, index) => {
              return (
                <EmployeeTableRow
                  key={`EmployeeCheckpointsTableRow-${field.key}-${index}`}
                  fieldKey={field.key}
                  fieldValue={employee[field.key]}
                  onBlur={handleEmployeeUpdate}
                  accountingUnits={
                    employers.find((employer) => employer.id === employee.employerId)?.accountingUnits || []
                  }
                />
              );
            }),
          )}

          {cardCheckpoints.flatMap((checkpoint) =>
            checkpoint.additionalData.fields.map((field, index) => {
              return (
                <EmployeeTableRow
                  key={`CardCheckpointsTableRow-${field.key}-${index}`}
                  fieldKey={field.key}
                  fieldValue={employeeCard[field.key]}
                  onBlur={handleEmployeeCardUpdate}
                />
              );
            }),
          )}
        </>
      </ListGroup>

      <When condition={!!payTypeMappings}>
        <Typography variant="body2">{t('report:employee-drawer.missing-pay-type-mappings')}</Typography>
      </When>
      <When condition={!!unfinishedReporting}>
        <Typography variant="body2">{t('report:employee-drawer.unfinished-reporting')}</Typography>
      </When>
    </div>
  );
};

export default UpdateEmployeeTable;
