import { InteractiveCard, Typography } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { Employee } from 'src/types/Employee';
import './wages-overview-section.scss';
import InteractiveCardBox from 'src/components/Common/InteractiveCardBox';
import WagesOverviewDrawer from '../WagesOverviewDrawer';
import EmployeeSectionLabel from '../../EmployeeSectionLabel';
import { formatSweAmountTextWithSuffixNoDecimal } from 'src/util/Number/AmountFormatter';
import { useTranslation } from 'react-i18next';
import { AggregatedEmployeeCard } from 'src/types/employees/AggregatedEmployeeCard';
import When from 'src/components/Common/When';
import { useEmployerWageTypes } from 'src/service/employer/GetEmployerWageTypes';
import { isSickLeaveWageType } from 'src/util/WageUtil';
import { stringOrBlank } from 'src/util/StringUtil';
import { generateUUID } from 'src/util/UUIDUtil';

interface WagesOverviewSectionProps {
  employee: Employee;
  aggregatedEmployeeCardWages: AggregatedEmployeeCard[];
  period: string;
}

const WagesOverviewSection: FC<WagesOverviewSectionProps> = ({ employee, period, aggregatedEmployeeCardWages }) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWageMonth, setSelectedWageMonth] = useState<number>();
  const currentEmployeeCard = aggregatedEmployeeCardWages.find((e) => e.period === period);

  const handleDisplayWageTypesDrawer = (month: number) => {
    setDrawerOpen(true);
    setSelectedWageMonth(month);
  };

  const handleDisplayOverviewDrawer = () => {
    setDrawerOpen(true);
  };

  const handleOnClose = () => {
    setDrawerOpen(false);
    setSelectedWageMonth(null);
  };

  const handleOnBackClick = () => {
    setSelectedWageMonth(null);
  };

  const employerWageTypes = useEmployerWageTypes(employee.employerId);
  const aggregatedWages = currentEmployeeCard
    ? [
        ...currentEmployeeCard.aggregatedWageTypeWages.filter(
          (w) => !isSickLeaveWageType(employerWageTypes.find((wt) => wt.id === w.employerWageTypeId)?.wageType),
        ),
        ...currentEmployeeCard.aggregatedPayTypeWages,
      ]
    : [];

  const hasChangesInDraft =
    currentEmployeeCard?.aggregatedWageTypeWages.some((aw) => aw.wages.some((w) => w.isDraftModified)) ?? false;

  return (
    <>
      <InteractiveCard
        label={<EmployeeSectionLabel label={t('wages:wages')} period={period} hasChangesInDraft={hasChangesInDraft} />}
        onClick={handleDisplayOverviewDrawer}
        data-cy="wages-overview-section__wages"
      >
        <div className="wages-overview-section">
          {aggregatedWages.slice(0, 3).map((aggregatedWage, index) => {
            const aggregatedAmount =
              'amount' in aggregatedWage ? aggregatedWage.amount : aggregatedWage.payedAmount ?? 0;

            const label =
              'employerWageTypeId' in aggregatedWage
                ? stringOrBlank(employerWageTypes.find((s) => s.id == aggregatedWage.employerWageTypeId)?.name)
                : `${aggregatedWage.payTypeNumber} - ${aggregatedWage.payTypeName}`;

            return (
              <InteractiveCardBox
                label={label}
                key={generateUUID()}
                value={formatSweAmountTextWithSuffixNoDecimal(aggregatedAmount)}
                onClick={() => handleDisplayWageTypesDrawer(+period.slice(4, 6))}
                index={index}
              />
            );
          })}
          <When condition={aggregatedWages.length > 3}>
            <InteractiveCardBox
              label={
                <Typography className="wages-overview-section__text-label" variant="body2" bold>{`+${
                  aggregatedWages.length - 3
                }`}</Typography>
              }
              onClick={() => handleDisplayWageTypesDrawer(+period.slice(4, 6))}
              data-cy="wages-overview-section__show-more"
            />
          </When>
        </div>
      </InteractiveCard>
      <When condition={drawerOpen}>
        <WagesOverviewDrawer
          period={period}
          open={drawerOpen}
          employee={employee}
          selectedWageMonth={selectedWageMonth}
          onClose={handleOnClose}
          onBackClick={handleOnBackClick}
          onDisplayWageTypesOverviewDrawer={handleDisplayWageTypesDrawer}
          isDraft={employee.isDraft}
          aggregatedEmployeeCards={aggregatedEmployeeCardWages}
        />
      </When>
    </>
  );
};

export default WagesOverviewSection;
