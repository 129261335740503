import { Button, ContentSlot, Divider, Icon, Typography } from 'fgirot-k2-ui-components';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './file-upload-container.scss';
import { uploadFile } from 'src/service/FileUpload';

interface FileUploadContainerProps {
  wageFilePeriod: string;
}
const FileUploadContainer = ({ wageFilePeriod }: FileUploadContainerProps) => {
  const { t } = useTranslation();
  const fileInputfield = useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    fileInputfield?.current?.click();
  };

  const resetFileInput = () => {
    fileInputfield.current.value = null;
  };

  const addFiles = (input: File[]) => {
    return Promise.resolve(input.forEach((file) => uploadFile(wageFilePeriod, file)));
  };
  const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    files &&
      addFiles(Array.from(files)).then(() => {
        resetFileInput();
      });
  };

  const preventDragEventDefault = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const files = e.dataTransfer?.files;
    addFiles(Array.from(files));
  };
  return (
    <div className="file-upload-container">
      <ContentSlot
        onDragOver={preventDragEventDefault}
        onDragEnter={preventDragEventDefault}
        onDragLeave={preventDragEventDefault}
        onDrop={handleDrop}
        className="file-upload"
      >
        <Icon type="Folder" />
        <div className="file-upload__drag-text">
          <Typography className="file-upload--text" variant="body2">
            <>{t('upload:file-upload.drop-files-1')}&nbsp;</>
          </Typography>
          <Typography className="file-upload--text" variant="body2" bold>
            <>{t('upload:file-upload.files')}&nbsp;</>
          </Typography>
          <Typography className="file-upload--text" variant="body2">
            {t('upload:file-upload.drop-files-2')}
          </Typography>
        </div>
        <div className="file-upload-divider">
          <Divider className="file-upload-divider--divider" />
          <Typography variant="body2" className="file-upload-divider--or">
            {t('common:or')}
          </Typography>

          <Divider className="file-upload-divider--divider" />
        </div>
        <Button onClick={handleFileUploadClick} size="large" label={t('upload:file-upload.upload')} />
      </ContentSlot>
      <input id="file" type="file" ref={fileInputfield} onChange={handleAddFiles} />
    </div>
  );
};

export default FileUploadContainer;
