import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import LoadingPage from 'src/components/Common/LoadingPage';
import DeviationsTable from './DeviationsTable';
import { EMPLOYEE_DEVIATIONS } from '../../../../graphql/schema/query/employee/EmployeeDevations';
import { EmployeeDeviationsPage } from '../../../../types/employees/EmployeeDeviationsPage';
import When from '../../../Common/When';
import { Banner } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';

interface DeviationsTabProps {
  wageFilePeriod: string;
}

const DeviationsTab = ({ wageFilePeriod }: DeviationsTabProps) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  return (
    <>
      <QueryWrapper
        query={EMPLOYEE_DEVIATIONS}
        loadingComponent={<LoadingPage />}
        options={{
          fetchPolicy: 'no-cache',
          variables: {
            employeeDeviations: {
              period: wageFilePeriod,
              pageNumber: pageNumber,
              pageSize: pageSize,
            },
          },
        }}
      >
        {({ employeeDeviations, paging }: EmployeeDeviationsPage) => {
          return (
            <>
              <When condition={employeeDeviations.length > 0}>
                <DeviationsTable
                  employeeDeviations={employeeDeviations}
                  paging={paging}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  onPageChange={handlePageChange}
                />
              </When>
              <When condition={employeeDeviations.length === 0}>
                <Banner message={t('validating:deviations-tab:table.success-message')} type="success" />
              </When>
            </>
          );
        }}
      </QueryWrapper>
    </>
  );
};

export default DeviationsTab;
