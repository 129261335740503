import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Button, Icon, InlineEdit, ListGroup, Pill, Typography } from 'fgirot-k2-ui-components';
import DrawerBase from 'src/components/Common/DrawerBase';
import { PglWage } from 'src/types/employees/PglOutcome';
import { formatSweAmountTextWithSuffixWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import './pgl-wage-type-details-drawer.scss';
import { getFormattedDateStringFromPeriod } from 'src/util/date/PeriodUtil';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import When from 'src/components/Common/When';

interface BonusOverviewDrawerProps {
  onClose: () => void;
  onBackClick: () => void;
  pglWage: PglWage;
  employerWageTypes: EmployerWageType[];
}

export const PglWageTypeDetailsDrawer: FC<BonusOverviewDrawerProps> = ({
  onClose,
  onBackClick,
  pglWage,
  employerWageTypes,
}) => {
  const { t } = useTranslation();
  const employerWageType = employerWageTypes.find(
    (employerWageType) => employerWageType.id === pglWage.employerWageTypeId,
  );

  const wages = [...pglWage.includedWages];
  const sortedWages = wages.sort((a, b) => {
    return Number(b.employeeCardPeriod) - Number(a.employeeCardPeriod);
  });

  return (
    <DrawerBase
      title={t(`wageTypes:${employerWageType.wageType}`)}
      leftButton={
        <Button type="link" label={t('common:back')} icon={<Icon type={'ArrowLeft'} />} onClick={onBackClick} />
      }
      onClose={onClose}
    >
      <Typography className="pgl-wage-type-details-drawer__heading" variant="body1" component="h3" bold>
        {t('calculations:employer-wage-types')}
      </Typography>
      {sortedWages.length === 0 && <Banner type="announcement" message={t('calculations:no-employee-wage-types')} />}
      <div className="pgl-wage-type-details-drawer">
        <ListGroup variant="inline-edit">
          {sortedWages.map((includedWage) =>
            includedWage.wageType === 'GROSS_SALARY' ? (
              <InlineEdit
                key={`PglWageTypeDetailsDrawer-${includedWage.id}`}
                label={
                  <div>
                    <Typography bold variant="body2" component="span">
                      {t(`wageTypes:${includedWage.wageType}`)}
                    </Typography>
                    <Pill
                      type="success"
                      size="small"
                      label={getFormattedDateStringFromPeriod(includedWage.employeeCardPeriod)}
                    />
                  </div>
                }
              >
                <Typography variant="body2">
                  {formatSweAmountTextWithSuffixWithTwoDecimals(includedWage.amount)}
                </Typography>
              </InlineEdit>
            ) : (
              <InlineEdit
                key={`PglWageTypeDetailsDrawer-${includedWage.id}`}
                label={
                  <div>
                    <Typography
                      bold
                      variant="body2"
                      component="span"
                    >{`${includedWage.payTypeNumber} - ${includedWage.payTypeName}`}</Typography>
                    <Pill
                      type="success"
                      size="small"
                      label={getFormattedDateStringFromPeriod(includedWage.employeeCardPeriod)}
                    />
                  </div>
                }
              >
                <Typography variant="body2">
                  {formatSweAmountTextWithSuffixWithTwoDecimals(includedWage.amount)}
                </Typography>
              </InlineEdit>
            ),
          )}
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('calculations:conversion-factor')}>
            <Typography variant="body2">{pglWage.conversionFactor.toString()}</Typography>
          </InlineEdit>
          <When condition={!!pglWage.conversionDenominator}>
            <InlineEdit bold label={t('calculations:conversion-denominator')}>
              <Typography variant="body2">{pglWage.conversionDenominator?.toString()}</Typography>
            </InlineEdit>
          </When>
        </ListGroup>
        <ListGroup variant="inline-edit">
          <InlineEdit bold label={t('calculations:sum')}>
            <Typography variant="body2">{formatSweAmountTextWithSuffixWithTwoDecimals(pglWage.pglSum)}</Typography>
          </InlineEdit>
        </ListGroup>
      </div>
    </DrawerBase>
  );
};
export default PglWageTypeDetailsDrawer;
