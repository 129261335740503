import { InlineEdit } from 'fgirot-k2-ui-components';
import UpdateDatePicker from 'src/components/Report/Common/UpdateField/UpdateDatePicker';
import UpdateInput from 'src/components/Common/UpdateInput';
import { useUpdateEmployeeCardWage } from 'src/service/employeecard/UpdateEmployeeCardWage';
import { Wage } from 'src/types/EmployeeCard';
import { useTranslation } from 'react-i18next';
import './update-multiple-monthly-salary-fromdate.scss';

interface MonthlSalaryWageRowProps {
  wage: Wage;
  employeeCardId: string;
  employerId: string;
}

const MonthlSalaryWageRow = ({ wage, employeeCardId, employerId }: MonthlSalaryWageRowProps) => {
  const { t } = useTranslation();

  const updateEmployeeCardWage = useUpdateEmployeeCardWage();
  const handleEmployeeCardWageUpdate = (field: 'fromDate' | 'amount', value: string) => {
    updateEmployeeCardWage(
      {
        id: employeeCardId,
        wageId: wage.id,
        fieldKey: field,
        fieldValue: value,
      },
      employerId,
    );
  };

  return (
    <>
      <InlineEdit label={t('examination:drawer.from-date')} bold>
        <UpdateDatePicker fieldKey={'fromDate'} onBlur={handleEmployeeCardWageUpdate} fieldValue={wage.fromDate} />
      </InlineEdit>

      <InlineEdit label={t('examination:drawer.amount')} bold>
        <UpdateInput
          type="number"
          fieldKey={'amount'}
          fieldValue={wage.amount.toString()}
          onBlur={handleEmployeeCardWageUpdate}
          validationKey="amount"
        />
      </InlineEdit>
    </>
  );
};

export default MonthlSalaryWageRow;
