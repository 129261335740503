import { TableHead, TableRow, TableBody, Td, SortDirection } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { Invoice } from 'src/types/invoice/Invoice';
import { useNavigate } from 'react-router-dom';
import InvoiceStatusTd from './InvoiceStatusTd/InvoiceStatusTd';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import { InvoiceTableSortField } from './InvoiceTableSortField';
import InvoiceTableHeads from './InvoiceTableHeads';
import { formatSweAmountTextAlwaysWithTwoDecimals } from 'src/util/Number/AmountFormatter';
import { stringOrBlank } from 'src/util/StringUtil';

interface InvoicesTableProps {
  invoices: Invoice[];
}

const InvoicesTable: FC<InvoicesTableProps> = ({ invoices }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [sortField, setSortField] = useState<InvoiceTableSortField>();
  const [sortDirection, setSortDirection] = useState<SortDirection>('DOWN');
  const handleSortFieldChange = (sortField: InvoiceTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));

  const sortFunction = (a: Invoice, b: Invoice) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalDesc : sortNumericalAsc;
    const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;
    switch (sortField) {
      case 'insuranceProvider.legalName':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'agreementNumber':
        return sortAlphabetical(a.agreementNumber, b.agreementNumber);
      case 'invoiceNumber':
        return sortNumerical(a[sortField], b[sortField]);
      case 'totalAmount':
        return sortNumerical(a[sortField], b[sortField]);
      case 'remainingAmount':
        return sortNumerical(a[sortField], b[sortField]);
      case 'ocrNumber':
        return sortNumerical(a[sortField], b[sortField]);
      case 'dueDate':
        return sortDate(a.dueDate, b.dueDate);
      case 'invoiceStatus':
        return sortAlphabetical(t(`invoice:status.${a.invoiceStatus}`), t(`invoice:status.${b.invoiceStatus}`));
      default:
        return undefined;
    }
  };

  const sortedInvoices = [...invoices].sort(sortFunction) || [];

  return (
    <TableBase maxHeightPercentage={65}>
      <TableHead filled>
        <InvoiceTableHeads
          sortDirection={sortDirection}
          sortField={sortField}
          onSortDirectionChange={handleSortDirectionChange}
          onSortFieldChange={handleSortFieldChange}
        />
      </TableHead>
      <TableBody>
        {sortedInvoices.map((invoice, index) => {
          const handleSelectInvoice = () => navigate(`/economy/invoices/${invoice.id}`);
          return (
            <TableRow key={`invoice-table-row-${invoice.id}-${index}`} onClick={handleSelectInvoice}>
              <Td>{stringOrBlank(invoice.insuranceProvider?.legalName)}</Td>
              <Td>{stringOrBlank(invoice.agreementNumber)}</Td>
              <Td>{stringOrBlank(invoice.invoiceNumber)}</Td>
              <Td alignRight>{stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(invoice.totalAmount))}</Td>
              <Td alignRight>{stringOrBlank(formatSweAmountTextAlwaysWithTwoDecimals(invoice.remainingAmount))}</Td>
              <Td>{stringOrBlank(invoice.ocrNumber)}</Td>
              <Td>{stringOrBlank(invoice.paymentDueDate)}</Td>
              <InvoiceStatusTd invoice={invoice} />
            </TableRow>
          );
        })}
      </TableBody>
    </TableBase>
  );
};

export default InvoicesTable;
