import { gql } from '@apollo/client';

export const DATA_VALIDATION_CHECKPOINT = gql`
  subscription DataValidationEventCheckpoint($employerIds: [UUID]!, $wageFilePeriod: String!) {
    checkpoint(employerIds: $employerIds, wageFilePeriod: $wageFilePeriod, actionCategory: "DATA_VALIDATION_EVENT") {
      id
      errandId
      errandEntryId
      employeeId
      employerId
      actionCategory
      subActionCategory
      checkpointStatus
      finalized
      additionalData {
        employerOrganisationNumber
        employeeCardId
        employeeCardPeriod
        eventFlowType
        validationPhaseRule
        fields {
          key
          value
          previous
          rawValue
        }
        wageTypeMappings {
          code
          description
        }
      }
    }
  }
`;
