import { useState, useEffect } from 'react';
import { getCustomerId } from 'src/service/customer/GetCustomerId';
import { updateKeycloakWithSelectedCustomer } from 'src/service/updateKeycloakWithSelectedCustomer';

const useCustomerSelected = () => {
  const customerId = getCustomerId();
  const [customerSelected, setCustomerSelected] = useState(false);

  useEffect(() => {
    const selectCustomerFromSessionStorage = async (id) => {
      const response = await updateKeycloakWithSelectedCustomer(id);
      if (response) {
        setCustomerSelected(true);
      }
    };

    if (customerId) {
      setCustomerSelected(true);
    } else {
      const customerIdFromStorage = JSON.parse(sessionStorage.getItem('customerId'));
      if (customerIdFromStorage) {
        selectCustomerFromSessionStorage(customerIdFromStorage);
      }
    }
  }, [customerId]);

  return customerSelected;
};

export default useCustomerSelected;
