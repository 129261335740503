import { DocumentNode, QueryHookOptions, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { Service } from 'src/types/Service';
import { INIT, LOADING, ERROR, LOADED } from 'src/types/Status';
import { extractDataField } from './GraphqlUtils';
import { Paging } from 'src/types/PageableResponse';
import { client } from 'src/graphql';
import { useTranslation } from 'react-i18next';
import { ToastContext } from 'fgirot-k2-ui-components';

export const useApolloQuery = <T>(query: DocumentNode, options?: QueryHookOptions, useErrorToast = true) => {
  const [result, setResult] = useState<Service<T>>({
    status: INIT,
  });
  const dataField = extractDataField(query);
  const { loading, error, data } = useQuery(query, options);
  const { displayToast, toastMessage, toastTitle } = prepareToast(query);

  useEffect(() => {
    loading && setResult({ status: LOADING });
    error && setResult({ status: ERROR, error });
    error && useErrorToast && displayToast('error', toastTitle, toastMessage);
    data && setResult({ status: LOADED, payload: data[dataField] });
  }, [loading, error, data]);

  return result;
};

export const useApolloQueryList = <T>(query: DocumentNode, options?: QueryHookOptions, useErrorToast = true) => {
  const [result, setResult] = useState<T[]>([]);
  const dataField = extractDataField(query);
  const { error, data } = useQuery(query, options);
  const { displayToast, toastMessage, toastTitle } = prepareToast(query);

  useEffect(() => {
    //TODO: how to handle error?
    data && setResult(data[dataField]);
    error && useErrorToast && displayToast('error', toastTitle, toastMessage);
  }, [error, data]);

  return result;
};

export const useApolloQueryPageable = <T>(query: DocumentNode, options?: QueryHookOptions) => {
  const [result, setResult] = useState<T[]>([]);
  const [paging, setPaging] = useState<Paging>();
  const [pageNumber, setPageNumber] = useState(0);
  const pageSize = 20;

  useEffect(() => {
    setResult([]);
    setPageNumber(0);
    setPaging(null);
  }, [options]);

  const dataField = extractDataField(query);
  const { loading, error, data, fetchMore } = useQuery(query, {
    ...options,
    variables: { ...options.variables, request: { ...options.variables.request, pageNumber, pageSize } },
  });

  useEffect(() => {
    //TODO: how to handle error?
    data && setPaging(data[dataField]['paging']);
    data && setResult((prev) => [...prev, ...data[dataField][dataField]]);
  }, [error, loading]);

  return {
    result,
    paging,
    fetchMore: () => {
      paging.hasNext &&
        setPageNumber((prevPageNumber) => {
          prevPageNumber++;

          fetchMore({
            ...options,
            variables: {
              ...options.variables,
              request: { ...options.variables.request, pageNumber: prevPageNumber, pageSize },
            },
          });
          return prevPageNumber;
        });
    },
  };
};

const prepareToast = (query: DocumentNode) => {
  const { t } = useTranslation();
  const { displayToast } = useContext(ToastContext);
  const queryName = getQueryName(query);
  const toastTitle = t('common:toast-error');
  const toastMessage = t([`userFeedback:${queryName}.error`, `userFeedback:fallback.error`]);

  return { displayToast, toastTitle, toastMessage };
};

const getQueryName = (query: DocumentNode) => {
  return query?.definitions?.[0]?.['name']?.value;
};

export const refetchQuery = (refetch?: DocumentNode | DocumentNode[]) => {
  const refetchQueries = Array.isArray(refetch) ? refetch : [refetch];
  client.refetchQueries({
    include: refetchQueries,
  });
};
