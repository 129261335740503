import { gql } from '@apollo/client';

export const CREATE_DRAFT_EMPLOYEE_CARD_WAGE = gql`
  mutation CreateDraftEmployeeCardWage($request: CreateDraftEmployeeCardWage!) {
    createDraftEmployeeCardWage(request: $request) {
      id
      wageType
      employerWageTypeId
      payTypeName
      payTypeNumber
      amount
      currency
      fromDate
      toDate
      inUse
      payTypeName
      payTypeNumber
    }
  }
`;
