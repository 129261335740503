import React, { FC, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import When from 'src/components/Common/When';
import { PremiumInvoiceRow } from 'src/types/invoice/PremiumInvoiceRow';
import PremiumInvoiceRowsTable from './PremiumInvoiceRowsTable';
import PremiumInvoiceRowsFiltersModal from './SearchPremiumInvoiceRowsFiltersModal';
import PremiumInvoiceRowsSearchInput from './PremiumInvoiceRowsSearchInput';
import PremiumInvoiceRowsFilterDetails from './PremiumInvoiceRowsFilterDetails';
import {
  SearchPremiumInvoiceRowsFilters,
  SearchPremiumInvoiceRowsRequest,
} from 'src/types/invoice/SearchPremiumInvoiceRowsRequest';
import { createPageSizeOptions } from 'src/util/OptionUtil';

interface PremiumInvoiceRowsProps {
  premiumInvoiceRows: PremiumInvoiceRow[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  request: SearchPremiumInvoiceRowsRequest;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchPremiumInvoiceRowsFilters) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const PremiumInvoiceRows: FC<PremiumInvoiceRowsProps> = ({
  premiumInvoiceRows,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  request,
  onTextSearch,
  onApplyFilters,
  onPageChange,
}) => {
  const { t } = useTranslation();

  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = getTotalPages(paging.totalNumberOfElements, request.pageSize);
  const showPagination = totalPages > 1;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  return (
    <>
      <div className="premium-invoice-rows-overview">
        <PremiumInvoiceRowsSearchInput onSearch={onTextSearch} searchRequest={request} />
        <PremiumInvoiceRowsFilterDetails searchRequest={request} applyFilters={onApplyFilters} />
        <PremiumInvoiceRowsTable premiumInvoiceRows={premiumInvoiceRows} />
        <When condition={showPagination}>
          <div data-testid="premium-invoice-rows-overview-pagination">
            <Pagination
              currentPage={pageNumber + 1}
              totalPages={totalPages}
              onPageChange={onPageChange}
              pageSize={pageSize}
              pageSizeLabel={t('common:results-per-page')}
              pageSizeOptions={pageSizeOptions}
              onPageSizeChange={setPageSize}
              pageSizePosition="left"
            />
          </div>
        </When>
      </div>
      <When condition={filtersModalOpen}>
        <PremiumInvoiceRowsFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={request?.filters}
        />
      </When>
      <div className="premium-invoice-rows-overview__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-cy="filter-button"
        />
      </div>
    </>
  );
};

export default PremiumInvoiceRows;
