import { Pill, Tab, Tabs, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import { DocumentTemplate } from 'src/types/documents/DocumentTemplate';
import { Employer } from 'src/types/employer/Employer';
import DrawerBreadcrumbs from 'src/components/Common/DrawerBreadcrumbs';
import { Customer } from '../../../../types/customer/Customer';
import GenerateDocumentDrawerTab from './GenerateDocumentDrawerTab';
import { useEmployersIdLegalName } from 'src/service/employer/GetEmployer';
import './document-template-drawer.scss';

interface DocumentTemplateDrawerProps {
  open: boolean;
  onClose: () => void;
  documentTemplate: DocumentTemplate;
  customer: Customer;
}

const DocumentTemplateDrawer = ({ open, onClose, documentTemplate, customer }: DocumentTemplateDrawerProps) => {
  const { t } = useTranslation();

  const breadcrumbs = [
    t('documents:document-templates-tab.drawer.breadcrumbs.document'),
    t('documents:document-templates-tab.drawer.breadcrumbs.templates'),
  ];

  const employers: Employer[] = useEmployersIdLegalName(customer?.id);

  return (
    <div className="document-template-drawer">
      <DrawerModal open={open} overlayBackground overlayOnClose onClose={onClose}>
        <DrawerBase
          onClose={onClose}
          title={
            <>
              <DrawerBreadcrumbs breadcrumbs={breadcrumbs} />
              <Typography variant="h3" component="h3" bold>
                {`${documentTemplate.name ?? ''}`}
              </Typography>
            </>
          }
          subTitle={
            <div className="document-template-drawer__subtitle">
              {documentTemplate.description}
              <Pill label={t(`documents:format-types.${documentTemplate.formatType}`)} />
            </div>
          }
        >
          <div className="document-template-drawer__content">
            <div>
              <Tabs>
                <Tab
                  title={t('documents:document-templates-tab.drawer.generate-document-drawer-tab.tab-title')}
                  data-testid="generate-report-tab"
                >
                  <GenerateDocumentDrawerTab
                    documentTemplate={documentTemplate}
                    employers={employers}
                    customer={customer}
                    onClose={onClose}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </DrawerBase>
      </DrawerModal>
    </div>
  );
};

export default DocumentTemplateDrawer;
