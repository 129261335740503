import { Icon, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { EmployeeCardBenefit } from 'src/types/employees/EmployeeCardBenefit';
import './benefit-details-affiliation-tab.scss';
import When from 'src/components/Common/When';

interface BenefiitDetailsAffiliationTabProps {
  benefit: EmployeeCardBenefit;
}

const BenefitDetailsAffiliationTab = ({ benefit }: BenefiitDetailsAffiliationTabProps) => {
  const { t } = useTranslation();

  return (
    <div className="benefit-details-affiliation-tab">
      <Typography variant="subtitle" bold>
        {t('benefits:benefits-drawer.affiliation-qualified')}
      </Typography>
      <ListGroup variant="inline-edit">
        {benefit.benefitRuleOutcomes.map((benefitRuleOutcome) => {
          return (
            <InlineEdit
              label={t(`policyRules:policyRuleType.${benefitRuleOutcome.policyRuleType}`)}
              bold
              key={benefitRuleOutcome.policyBenefitRuleId}
            >
              <div className="benefit-details-affiliation-tab__value">
                <span className="benefit-details-affiliation-tab__icon">
                  {benefitRuleOutcome.isSatisfied ? (
                    <Icon type="Check" stroke="#479890" />
                  ) : (
                    <Icon type="Cross" stroke="#fa556b" />
                  )}
                </span>
                <When condition={!!benefitRuleOutcome.outcomeCause}>
                  <Typography variant="body2">
                    {t(`policyRules:outcomeCause.${benefitRuleOutcome.outcomeCause}`)}
                  </Typography>
                </When>
              </div>
            </InlineEdit>
          );
        })}
      </ListGroup>
    </div>
  );
};

export default BenefitDetailsAffiliationTab;
