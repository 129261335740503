import { gql } from '@apollo/client';

export const EMPLOYEE_DEVIATIONS = gql`
  query EmployeeDeviations($employeeDeviations: EmployeeDeviationsRequest) {
    employeeDeviations(request: $employeeDeviations) {
      employeeDeviations {
        id
        employer {
          id
          legalName
        }
        employmentNumber
        firstName
        lastName
        personNumber
        deviations
      }
      paging {
        totalNumberOfElements
        hasNext
      }
    }
  }
`;
