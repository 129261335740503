import { SearchInvoiceRowRequest } from 'src/types/invoice/SearchInvoiceRowsRequest';

export const getInitialSearchInvoiceRowRequest = (): SearchInvoiceRowRequest => {
  return {
    searchInput: '',
    pageNumber: 0,
    pageSize: 25,
    filters: {
      insuranceProviderIdsFilter: null,
    },
  };
};
