import { PayedAmountPerPeriod } from './UpdateWagesPayedAmount';
import { inputSchema } from 'src/validation/schemas/inputSchema';

export const payedAmountsAreValid = (payedAmounts: PayedAmountPerPeriod[]) => {
  let valid = true;

  if (payedAmounts.some((p) => !p.payedAmount)) {
    valid = false;
  }
  const errors = payedAmounts
    .map((p) => {
      const { error } = inputSchema.validate({ amount2DecAllowNegative: p.payedAmount }, { abortEarly: false });
      return error;
    })
    .filter((e) => e !== undefined);

  if (errors.length > 0) {
    valid = false;
  }

  return valid;
};
