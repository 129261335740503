import { TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { Employer } from 'src/types/employer/Employer';
import TdFullname from 'src/components/Report/Common/ReportTable/TdFullname';
import { EmployeeDeviation } from '../../../../../types/EmployeeDeviation';
import { useTranslation } from 'react-i18next';

interface DeviationTableRowProps {
  employeeDeviation: EmployeeDeviation;
  employer: Employer;
  onClick: (id: string) => void;
}

const DeviationTableRow = ({ employeeDeviation, employer, onClick }: DeviationTableRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow onClick={() => onClick(employeeDeviation.id)}>
      <TdFullname firstName={employeeDeviation.firstName} lastName={employeeDeviation.lastName} hasDraft={false} />
      <Td>{employeeDeviation.personNumber}</Td>
      <Td>{employer?.legalName}</Td>
      <Td>{employeeDeviation.employmentNumber}</Td>
      {employeeDeviation.deviations.length === 1 ? (
        <Td>{t('reporting:checkpoints.' + employeeDeviation.deviations[0])}</Td>
      ) : (
        <Td>
          {t('reporting:checkpoints.' + employeeDeviation.deviations[0]) + '  + ' + employeeDeviation.deviations.length}
        </Td>
      )}
    </TableRow>
  );
};

export default DeviationTableRow;
