import { TableBody, TableHead, TableRow, Th, Option, SortDirection } from 'fgirot-k2-ui-components';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import './wage-type-table.scss';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import FilterComponent from 'src/components/Common/FilterComponent';
import { useEmployerKubs } from 'src/service/employer/GetEmployerKubs';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import PayTypeTableRow from './PayTypeTableRow/PayTypeTableRow';

interface PayTypeTableHead {
  title: string;
  sort: (direction: SortDirection) => void;
  isKub?: boolean;
}

interface PayTypeTableProps {
  employerId: string;
  employerPayTypes: EmployerPayType[];
  employerWageTypes: EmployerWageType[];
  handleDrawerOpen: (id: string) => void;
}
const PayTypeTable: React.FunctionComponent<PayTypeTableProps> = ({
  employerId,
  employerPayTypes,
  employerWageTypes,
  handleDrawerOpen,
}) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<'employerPayType' | 'employerWageType' | 'inUse'>();
  const [sortDirection, setSortDirection] = useState<'UP' | 'DOWN'>('DOWN');
  const employerKubs = useEmployerKubs(employerId);

  const [nameFilter, setNameFilter] = useState<string>('');
  const [selectedWageTypes, setSelectedWageTypes] = useState<Option<EmployerWageType>[]>([]);

  const tableHeads = useMemo(() => {
    const kubTypeHeads: PayTypeTableHead[] = employerKubs.map((employerKub) => ({
      title: employerKub.kubType,
      sort: undefined,
      isKub: true,
    }));

    const tableHeads: PayTypeTableHead[] = [
      {
        title: t('account:employers-tab.wage-type-mapping-settings.table.employer-wage-type'),
        sort: (direction: SortDirection) => {
          setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
          setSortField('employerPayType');
        },
      },
      ...kubTypeHeads,
      {
        title: t('account:employers-tab.wage-type-mapping-settings.table.wage-type'),
        sort: (direction: SortDirection) => {
          setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
          setSortField('employerWageType');
        },
      },
      {
        title: t('account:employers-tab.wage-type-mapping-settings.in-use:title'),
        sort: (direction: SortDirection) => {
          setSortDirection((prev) => (prev === direction ? (direction === 'UP' ? 'DOWN' : 'UP') : direction));
          setSortField('inUse');
        },
      },
    ];

    return tableHeads;
  }, [employerKubs]);

  const sortEmployerPayTypes = (a: EmployerPayType, b: EmployerPayType) => {
    const intA = parseInt(a.number);
    const intB = parseInt(b.number);
    return sortDirection === 'UP' ? (intA > intB ? -1 : 1) : intA > intB ? 1 : -1;
  };

  const sortEmployerWageTypes = (a: EmployerPayType, b: EmployerPayType) => {
    const wageTypeA = a.employerWageType.name;
    const wageTypeB = b.employerWageType.name;

    return sortDirection === 'UP'
      ? wageTypeB.toLocaleLowerCase().localeCompare(wageTypeA.toLocaleLowerCase())
      : wageTypeA.toLocaleLowerCase().localeCompare(wageTypeB.toLocaleLowerCase());
  };

  const sortInUse = (a: EmployerPayType) => {
    return sortDirection === 'UP' ? (a.inUse ? -1 : 1) : a.inUse ? 1 : -1;
  };

  const sortMappings = (mappings: EmployerPayType[]) => {
    switch (sortField) {
      case 'employerPayType':
        return mappings.sort(sortEmployerPayTypes);
      case 'employerWageType':
        return mappings.sort(sortEmployerWageTypes);
      case 'inUse':
        return mappings.sort(sortInUse);
      default:
        return mappings;
    }
  };

  const handleNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameFilter(event.target.value);
  };

  const wageTypeFilterProps = {
    placeholder: t('wageTypeFilter:all-wage-types'),
    options: employerWageTypes.map((wageType) => ({
      label: wageType.name,
      value: wageType,
    })),
    selected: selectedWageTypes,
    onChange: (input: Option<EmployerWageType>[]): void => {
      setSelectedWageTypes([...input]);
    },
  };

  const filterEmployerPayType = (employerPayType: EmployerPayType, nameFilter: string): boolean => {
    return employerPayType.number.match(nameFilter.toLowerCase()) ||
      employerPayType.name.toLowerCase().match(nameFilter.toLowerCase())
      ? true
      : false;
  };

  const filterEmployerWageType = (payType: EmployerPayType): boolean => {
    return selectedWageTypes.length > 0
      ? selectedWageTypes.some(
          (selectedPayType) => selectedPayType.value.wageType === payType.employerWageType.wageType,
        )
      : true;
  };

  const filteredPayTypes = employerPayTypes
    .filter((payType) => filterEmployerPayType(payType, nameFilter))
    .filter((payType) => filterEmployerWageType(payType));

  const sortedPayTypes = sortMappings(filteredPayTypes);

  return (
    <>
      <FilterComponent
        showSearch
        searchPlaceholder={t('searchTerms:search-employer-wage-type')}
        searchFilter={nameFilter}
        onSearchFilterChange={handleNameFilterChange}
        selectProps={[wageTypeFilterProps]}
      ></FilterComponent>
      <div className={['wage-type-table'].join(' ')}>
        <TableBase maxHeightPercentage={55}>
          <TableHead filled>
            <TableRow>
              {tableHeads.map((head, index) => (
                <Th
                  includeSortButtons={head?.sort !== undefined}
                  handleSortDirectionChange={head.sort}
                  key={`EmploymentControlPointSettingsTable-${head.title}-${index}`}
                  className={head.isKub ? 'wage-type-table__kub-column' : ''}
                >
                  {head.title}
                </Th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedPayTypes.map((payType, index) => (
              <PayTypeTableRow
                key={`wage-type-table-row-${payType.number}-${index}`}
                employerPayType={payType}
                handleWageTypeMappingDrawer={handleDrawerOpen}
                employerKubs={employerKubs}
              />
            ))}
          </TableBody>
        </TableBase>
      </div>
    </>
  );
};

export default PayTypeTable;
