import { InlineEdit, ListGroup, Switch, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { InsuranceEventCheckpoint } from 'src/types/Checkpoint';
import { groupByInsuranceEvent } from '../../../utils';
import { InsuranceEvent } from 'src/types/reporting/InsuranceEvent';
import InsuranceEventLabel from '../InsuranceEventLabel';
import styles from './styles.module.scss';
import { useUpdateCheckpointStatusMutation } from 'src/service/checkpoint/UpdateCheckpointStatusMutation';
import { useTranslation } from 'react-i18next';

interface InsuranceEventPausedCheckpointProps {
  checkpoints: InsuranceEventCheckpoint[];
  insuranceEvents: Map<string, InsuranceEvent>;
}

interface GroupedCheckpoints {
  [key: string]: InsuranceEventCheckpoint[];
}

const InsuranceEventPausedCheckpoint: React.FC<InsuranceEventPausedCheckpointProps> = ({ checkpoints }) => {
  const insuranceEventTypeToCheckpointsMap = groupByInsuranceEvent(checkpoints);
  const updateCheckpoint = useUpdateCheckpointStatusMutation();
  const { t } = useTranslation();

  const isResolved = checkpoints.every((checkpoint) => checkpoint.checkpointStatus === 'ACCEPTED');

  const handleTogglePause = () => {
    const grouped: GroupedCheckpoints = checkpoints.reduce((map, element) => {
      map[element.employerId] = map[element.employerId] || [];
      map[element.employerId].push(element);
      return map;
    }, Object.create(null));

    Object.entries(grouped).forEach((entry) => {
      const employerId = entry[0];
      return updateCheckpoint(
        entry[1].map((checkpoint) => checkpoint.id),
        isResolved ? 'RAISED' : 'ACCEPTED',
        employerId,
      );
    });
  };

  return (
    <ListGroup variant="inline-edit">
      {Array.from(insuranceEventTypeToCheckpointsMap.keys()).map((key, idx) => {
        const insuranceEventTypeCheckpoint = insuranceEventTypeToCheckpointsMap.get(key);
        return (
          <InlineEdit
            key={`${key}-${idx}`}
            bold
            label={<InsuranceEventLabel checkpoints={insuranceEventTypeCheckpoint} />}
          >
            <div className={styles.switchContainer}>
              <Typography variant="body2">{t('subActionCategories:INSURANCE_EVENT_PAUSED')}</Typography>
              <Switch toggled={!isResolved} onChange={handleTogglePause} />
            </div>
          </InlineEdit>
        );
      })}
    </ListGroup>
  );
};

export default InsuranceEventPausedCheckpoint;
