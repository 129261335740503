import { useMutation } from '@apollo/client';
import { Button, Card, CardAction, ErrorSection, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useState } from 'react';
import { TRIGGER_REPORTING_QUEUE } from 'src/graphql/schema/mutation/report/TriggerReportingQueue';
import { Employer } from 'src/types/employer/Employer';
import { useTranslation } from 'react-i18next';
import { GET_REPORTING_EVENTS } from 'src/graphql/schema/query/reporting/GetReportingEvents';
import { client } from 'src/graphql';
import { useContractedInsuranceProviderOptions } from './useContractedInsuranceProviderOptions';

interface TriggerReportingQueueButtonProps {
  onSuccess: () => void;
  employers: Employer[];
}

const TriggerReportingQueueButton = ({ employers, onSuccess }: TriggerReportingQueueButtonProps) => {
  const [triggerReportingQueue, { loading, error }] = useMutation(TRIGGER_REPORTING_QUEUE);
  const { t } = useTranslation();
  const [selectedEmployer, setSelectedEmployer] = useState<Option<string>>(null);
  const [selectedProvider, setSelectedProvider] = useState<Option<string>>(null);

  const { employerOptions, insuranceProviderOptions } = useContractedInsuranceProviderOptions(
    selectedEmployer?.value,
    employers,
  );

  const [open, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const triggerQueueCalculation = () => {
    triggerReportingQueue({
      variables: {
        employerId: selectedEmployer.value,
        insuranceProviderId: selectedProvider.value,
      },
      context: { employerId: selectedEmployer.value },
    }).then(() => {
      client.refetchQueries({
        include: [GET_REPORTING_EVENTS],
      });
    });
    onSuccess();
    closeModal();
  };

  return (
    <>
      <Button label={t('reporting:queue-triggering.trigger-button')} size="small" onClick={openModal} />
      <Modal open={open} centered overlayBackground onClose={closeModal}>
        <Card title={t('reporting:queue-triggering.title')}>
          {error && (
            <ErrorSection>
              <Typography variant="caption">{error.message}</Typography>
            </ErrorSection>
          )}
          <div>
            <Select
              label={t('reporting:queue-triggering.employer')}
              small
              outlined
              placeholder={t('reporting:queue-triggering.choose-employer')}
              options={employerOptions}
              selected={selectedEmployer}
              onChange={setSelectedEmployer}
              disabled={!employerOptions || employerOptions?.length === 0}
            />
            <Select
              label={t('reporting:queue-triggering.insurance-provider')}
              small
              outlined
              placeholder={t('reporting:queue-triggering.choose-insurance-provider')}
              options={insuranceProviderOptions}
              selected={selectedProvider}
              onChange={setSelectedProvider}
              disabled={loading || insuranceProviderOptions?.length === 0}
            />
          </div>
          <CardAction>
            <Button label={t('common:cancel')} type="link" onClick={closeModal} />
            <Button
              label={t('reporting:queue-triggering.ok-button')}
              onClick={triggerQueueCalculation}
              disabled={loading || !selectedProvider || !selectedEmployer}
            />
          </CardAction>
        </Card>
      </Modal>
    </>
  );
};

export { TriggerReportingQueueButton };
