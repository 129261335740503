import React, { useEffect, useState } from 'react';
import { Wage } from 'src/types/EmployeeCard';
import './wage-details.scss';
import { Button, InlineEdit, ListGroup, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import TextInput from 'src/components/Common/TextInput';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import { UpdateDraftEmployeeCardWageRequest } from 'src/types/employees/request/UpdateDraftEmployeeCardWageRequest';
import { useUpdateDraftEmployeeCardWage } from 'src/service/employeecard/UpdateDraftEmployeeCardWage';
import { stringOrBlank } from 'src/util/StringUtil';
import { hasAmountChanged } from '../utils';
import { formatPayType, globalAbsenceWageTypes, wageIsOnlyMappedToKUB } from 'src/util/WageUtil';
import InfoMessage from 'src/components/Common/InfoMessage';
import AmountInput from 'src/components/Common/Wage/inputs/AmountInput';
import PayedAmountInput from 'src/components/Common/Wage/inputs/PayedAmountInput';
import { parseSweAmountTextWithNegativeAmounts } from 'src/util/Number/AmountFormatter';
import { inputSchema } from 'src/validation/schemas/inputSchema';
import When from 'src/components/Common/When';

interface WageDetailsProps {
  wage: Wage;
  employeeCardId: string;
  employerId: string;
  isDraft: boolean;
  onBackClick: () => void;
  employerWageTypes: EmployerWageType[];
}

function WageDetails({ wage, employeeCardId, isDraft, employerId, onBackClick, employerWageTypes }: WageDetailsProps) {
  const [amount, setAmount] = useState(wage.amount.toString().replace('.', ','));
  const [payedAmount, setPayedAmount] = useState(wage.payedAmount ? wage.payedAmount.toString().replace('.', ',') : '');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const updateDraftEmployeeCardWage = useUpdateDraftEmployeeCardWage();

  const { t } = useTranslation();

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const handlePayedAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPayedAmount(e.target.value);
  };

  const handleSaveChanges = async () => {
    setSaveButtonDisabled(true);

    const request: UpdateDraftEmployeeCardWageRequest = {
      employeeCardId,
      wageId: wage.id,
      changes: [
        {
          fieldKey: 'amount',
          fieldValue: parseSweAmountTextWithNegativeAmounts(amount),
        },
      ],
    };

    if (shouldDisplayPayedAmountInput) {
      request.changes.push({
        fieldKey: 'payedAmount',
        fieldValue: parseSweAmountTextWithNegativeAmounts(payedAmount),
      });
    }

    await updateDraftEmployeeCardWage(request, employerId);
    onBackClick();
  };

  useEffect(() => {
    const amountValidation = inputSchema.validate({
      amount2DecAllowNegative: amount,
    });
    const payedAmountValidation = inputSchema.validate({
      amount2DecAllowNegative: payedAmount,
    });

    if (
      !amountValidation.error &&
      !payedAmountValidation.error &&
      (hasAmountChanged(wage.amount, amount) || hasAmountChanged(wage.payedAmount, payedAmount))
    ) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [amount, payedAmount]);

  const employerWageType = employerWageTypes.find((w) => w.id === wage.employerWageTypeId);

  const isAbsenceWageType = globalAbsenceWageTypes.includes(wage?.wageType);

  const shouldDisplayPayedAmountInput = wage.kubTypes?.length > 0 && !isAbsenceWageType;

  const isOnlyMappedToKub = wageIsOnlyMappedToKUB(wage);

  return (
    <div className="wage-details">
      {isDraft && isAbsenceWageType && <InfoMessage message={t('wages:absence-wages-cannot-be-edited')} />}
      <ListGroup variant="inline-edit">
        <InlineEdit label={t('wages:pay-type')} bold>
          {isDraft && !isAbsenceWageType ? (
            <TextInput value={`${wage.payTypeNumber} - ${wage.payTypeName}`} onChange={null} disabled />
          ) : (
            <Typography variant="body2">{formatPayType(wage.payTypeNumber, wage.payTypeName, t)}</Typography>
          )}
        </InlineEdit>
        <When condition={!isOnlyMappedToKub}>
          <InlineEdit label={t('wages:wage-type')} bold>
            {isDraft && !isAbsenceWageType ? (
              <TextInput value={employerWageType?.name || ''} onChange={null} disabled />
            ) : (
              <Typography variant="body2">{stringOrBlank(employerWageType?.name)}</Typography>
            )}
          </InlineEdit>
          <AmountInput
            isEditable={isDraft && !isAbsenceWageType}
            amount={amount}
            handleAmountChange={handleAmountChange}
          />
        </When>
        <When condition={shouldDisplayPayedAmountInput}>
          <PayedAmountInput
            isEditable={isDraft && !isAbsenceWageType}
            payedAmount={payedAmount}
            handlePayedAmountChange={handlePayedAmountChange}
          />
          <InfoMessage message={`${t('wages:payed-amount-info')} ${wage.kubTypes.join(', ')}`} />
        </When>
      </ListGroup>
      <When condition={isDraft}>
        <Button
          type="primary"
          label={t('common:save-changes')}
          onClick={handleSaveChanges}
          disabled={saveButtonDisabled || isAbsenceWageType}
        />
      </When>
    </div>
  );
}

export default WageDetails;
