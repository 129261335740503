import { SortDirection, TableBody, TableHead } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import { AccountingItem } from 'src/types/ledgerStorage/AccountingItem';
import {
  sortAlphabeticalAsc,
  sortAlphabeticalDesc,
  sortDateAsc,
  sortDateDesc,
  sortNumericalAsc,
  sortNumericalDesc,
} from 'src/util/SortUtil';
import AccountingItemsTableHeads from './AccountingItemsTableHeads';
import { AccountingItemsTableSortField } from './AccountingItemsTableSortField';
import AccountingItemTableRow from './AccountingItemTableRow';
import { unique } from 'src/util/ArrayUtil';

interface AccountingItemsTableProps {
  accountingItems: AccountingItem[];
}

const AccountingItemsTable: FC<AccountingItemsTableProps> = ({ accountingItems }) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState<AccountingItemsTableSortField>('accountingPeriod');
  const [sortDirection, setSortDirection] = useState<SortDirection>('UP');

  const handleSortFieldChange = (sortField: AccountingItemsTableSortField) => setSortField(sortField);
  const handleSortDirectionChange = () => setSortDirection((prev) => (prev === 'UP' ? 'DOWN' : 'UP'));

  const sortFunction = (a: AccountingItem, b: AccountingItem) => {
    const sortAlphabetical = sortDirection === 'UP' ? sortAlphabeticalAsc : sortAlphabeticalDesc;
    const sortNumerical = sortDirection === 'UP' ? sortNumericalDesc : sortNumericalAsc;
    const sortDate = sortDirection === 'UP' ? sortDateDesc : sortDateAsc;
    switch (sortField) {
      case 'firstName':
        return sortAlphabetical(a.employee.firstName, b.employee.firstName);
      case 'lastName':
        return sortAlphabetical(a.employee.lastName, b.employee.lastName);
      case 'personNumber':
        return sortAlphabetical(a.employee.personNumber, b.employee.personNumber);
      case 'accountingPeriod':
        return sortDate(a[sortField], b[sortField]);
      case 'premiumPeriod':
        return sortDate(a[sortField], b[sortField]);
      case 'accountingNumber':
        return sortAlphabetical(a.accountingNumber, b.accountingNumber);
      case 'accountingTypeValue':
        return sortAlphabetical(
          t(`accountingTypeValue:${a.accountingTypeValue}`),
          t(`accountingTypeValue:${b.accountingTypeValue}`),
        );

      case 'amount':
        return sortNumerical(a.amount, b.amount);
      case 'invoiceNumber':
        return sortAlphabetical(a.invoiceNumber, b.invoiceNumber);
      case 'insuranceProvider':
        return sortAlphabetical(a.insuranceProvider.legalName, b.insuranceProvider.legalName);
      case 'benefitType':
        return sortAlphabetical(
          t(`benefitTypes:${a.insurance?.benefitType}`),
          t(`benefitTypes:${b.insurance?.benefitType}`),
        );
      case 'insuranceType':
        return sortAlphabetical(a.insuranceTypeMapping?.name, b.insuranceTypeMapping?.name);
      // default has to handle dynamic accounting components, since they can be anything

      default:
        return sortAlphabetical(
          a.accountingComponents.find(
            (component) => component.accountingComponentValue.accountingComponentType.name === sortField,
          )?.accountingComponentValue?.accountingComponentType?.name,
          b.accountingComponents.find(
            (component) => component.accountingComponentValue.accountingComponentType.name === sortField,
          )?.accountingComponentValue?.accountingComponentType.name,
        );
    }
  };

  const sortedAccountingItems = [...accountingItems].sort(sortFunction) || [];

  const uniqueComponentTypes = unique(
    sortedAccountingItems.flatMap((item) =>
      item.accountingComponents
        .map((component) => component.accountingComponentValue?.accountingComponentType?.name)
        .filter((type) => type),
    ),
  ).sort(sortAlphabeticalAsc);

  return (
    <TableBase compressed>
      <TableHead filled>
        <AccountingItemsTableHeads
          accountingComponentTypes={uniqueComponentTypes}
          sortDirection={sortDirection}
          sortField={sortField}
          onSortDirectionChange={handleSortDirectionChange}
          onSortFieldChange={handleSortFieldChange}
        />
      </TableHead>
      <TableBody>
        {sortedAccountingItems.map((item, index) => (
          <AccountingItemTableRow
            key={`accounting-item-table-row-${item.id}-${index}`}
            accountingComponentTypes={uniqueComponentTypes}
            accountingItem={item}
          />
        ))}
      </TableBody>
    </TableBase>
  );
};

export default AccountingItemsTable;
