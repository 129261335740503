import React, { useState } from 'react';
import DrawerModal from 'src/components/Common/DrawerModal';
import { PglBonusComponent } from 'src/types/policy/PglBonusComponent';
import { PglRule } from 'src/types/policy/PglRule';
import { PglWageType } from 'src/types/policy/PglWageType';
import { PolicyState } from 'src/types/policy/PolicyEntity';
import './pgl-rule-drawer.scss';
import PglCalculationDetails from './PglCalculationDetails';
import PglRuleOverview from './PglRuleOverview/PglRuleOverview';
import { PolicyPglRuleDrawerTab } from 'src/types/tabs/PolicyPglRuleDrawerTab';
import MinimumReportableSetting from './PglCalculationDetails/MinimumReportableSetting';
import { useUpdatePGLRule } from 'src/service/policy/UpdatePGLRule';
import { UpdatePGLRuleRequest } from 'src/types/policy/request/UpdatePGLRuleRequest';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_EMPLOYER_WAGE_TYPES } from 'src/graphql/schema/query/employer/GetEmployerWageTypes';
import { EmployerWageType } from 'src/types/employer/EmployerWageType';
import When from 'src/components/Common/When';

interface PglRuleDrawerProps {
  employerId: string;
  pglRule: PglRule;
  open: boolean;
  onClose: () => void;
  policyState: PolicyState;
  policyId: string;
}

const PglRuleDrawer = ({ employerId, pglRule, open, onClose, policyState, policyId }: PglRuleDrawerProps) => {
  const [selectedPglWageType, setSelectedPglWageType] = useState<PglWageType>();
  const [selectedPglBonusComponent, setSelectedPglBonusComponent] = useState<PglBonusComponent>();
  const [creatingNewCalculation, setCreatingNewCalculation] = useState(false);
  const [currentTab, setCurrentTab] = useState<PolicyPglRuleDrawerTab>(PolicyPglRuleDrawerTab.OVERVIEW);
  const [editMinimumReportableSetting, setEditMinimumReportableSetting] = useState(false);
  const inDraft = policyState === 'DRAFT';
  const pglType = selectedPglWageType && pglRule.spglWageTypes.includes(selectedPglWageType) ? 'SPGL' : 'PGL';
  const updatePGLRule = useUpdatePGLRule();

  const reset = (func?: () => void) => {
    setSelectedPglWageType(undefined);
    setSelectedPglBonusComponent(undefined);
    setCreatingNewCalculation(false);
    setEditMinimumReportableSetting(false);
    func && func();
  };

  const handleSelectPglWageType = (pglWageTypeId: string) => {
    let pglWageTypes = [];
    if (pglRule != null) {
      pglWageTypes = [...pglRule.spglWageTypes, ...pglRule.pglWageTypes];
    }

    reset(() => setSelectedPglWageType(pglWageTypes.find((pglWageType) => pglWageType.id === pglWageTypeId)));
  };

  const handleSelectPglBonusComponent = (pglBonusComponentId: string) =>
    reset(() =>
      setSelectedPglBonusComponent(
        pglRule?.pglBonusComponents.find((pglBonusComponent) => pglBonusComponent.id === pglBonusComponentId),
      ),
    );

  const handleCreateNewCalculation = () => reset(() => setCreatingNewCalculation(true));

  const handleEditMinimumReportableSetting = () => reset(() => setEditMinimumReportableSetting(true));

  const handleUpdatePglRule = (request: UpdatePGLRuleRequest) =>
    reset(() => updatePGLRule(employerId, policyId, pglRule.id, request));

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <QueryWrapper query={GET_EMPLOYER_WAGE_TYPES} options={{ variables: { employerId } }}>
        {(employerWageTypes: EmployerWageType[]) => (
          <>
            <When
              condition={
                !editMinimumReportableSetting &&
                !selectedPglWageType &&
                !selectedPglBonusComponent &&
                !creatingNewCalculation
              }
            >
              <PglRuleOverview
                pglRule={pglRule}
                onClose={onClose}
                inDraft={inDraft}
                handleSelectPglWageType={handleSelectPglWageType}
                handleSelectBonusComponent={handleSelectPglBonusComponent}
                handleCreateNewCalculation={handleCreateNewCalculation}
                handleEditMinimumReportableSetting={handleEditMinimumReportableSetting}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                employerWageTypes={employerWageTypes}
              />
            </When>

            <When
              condition={
                !!(selectedPglWageType || selectedPglBonusComponent || creatingNewCalculation) &&
                !editMinimumReportableSetting
              }
            >
              <PglCalculationDetails
                employerId={employerId}
                pglRuleName={pglRule.name}
                pglRuleId={pglRule.id}
                policyState={policyState}
                policyId={policyId}
                pglBonusComponent={selectedPglBonusComponent}
                pglWageType={selectedPglWageType}
                onBackClick={reset}
                onClose={onClose}
                employerWageTypes={employerWageTypes}
                pglType={pglType}
              />
            </When>

            <When condition={editMinimumReportableSetting}>
              <MinimumReportableSetting
                inDraft={inDraft}
                onClose={onClose}
                onBackClick={reset}
                handleUpdate={handleUpdatePglRule}
                pglRuleName={pglRule.name}
                minimumReportableAmount={pglRule.minimumReportableChangeAmount}
              />
            </When>
          </>
        )}
      </QueryWrapper>
    </DrawerModal>
  );
};

export default PglRuleDrawer;
