import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolicyBenefit } from 'src/types/policy/PolicyBenefit';
import { ACTIVE, DRAFT, PolicyEntity } from 'src/types/policy/PolicyEntity';
import { PolicyVersionChanges } from 'src/types/policy/PolicyVersionChanges';
import { RuleValue, RuleValueCharCode } from 'src/types/policy/RuleValue';
import { AffiliationRuleGroupType, GroupedAffiliationRules } from 'src/types/policy/GroupedAffiliationRules';
import { AffiliationRuleBlock } from 'src/types/policy/AffiliationRuleBlock';
import { CalculationTimeFrame, PglWageType } from '../types/policy/PglWageType';
import { WageType } from '../types/employer/EmployerWageType';

export const getEmploymentGroupNamesForBenefit = (policyBenefit: PolicyBenefit): string => {
  return policyBenefit.policyEmploymentGroups
    ? policyBenefit.policyEmploymentGroups.map((gr) => gr.employmentGroupName).join(', ')
    : '-';
};

export const getFirstRuleValue = (ruleValues: RuleValue[]): RuleValue => {
  return ruleValues && ruleValues.find((value) => value);
};

export const getPreSelectedPolicyId = (policyEntities: PolicyEntity[]) => {
  const preSelectedPolicy =
    policyEntities.find((policyEntity) => policyEntity.state === ACTIVE) ||
    policyEntities.find((policyEntity) => policyEntity.state === DRAFT) ||
    (policyEntities &&
      policyEntities.length > 0 &&
      policyEntities.reduce((currentEntity, previousEntity) =>
        parseInt(currentEntity?.version) > parseInt(previousEntity.version) ? currentEntity : previousEntity,
      ));

  return preSelectedPolicy ? preSelectedPolicy.id : undefined;
};

export const getSumChanges = (policyVersionChanges: PolicyVersionChanges) => {
  return (
    policyVersionChanges &&
    policyVersionChanges.changedBenefits.length +
      policyVersionChanges.changedEmploymentGroups.length +
      policyVersionChanges.changedPglRules.length
  );
};

export const getSelectedRuleGroupTypeFromBlocks = (
  ruleGroupType: AffiliationRuleGroupType,

  affiliationRuleBlocks: AffiliationRuleBlock[],
) => {
  const groupedAffiliationRules = affiliationRuleBlocks?.flatMap((ruleBlock) => ruleBlock.groupedAffiliationRules);
  return getSelectedRuleGroupTypeFromList(ruleGroupType, groupedAffiliationRules);
};

export const getSelectedRuleGroupTypeFromList = (
  ruleGroupType: AffiliationRuleGroupType,
  groupedAffiliationRules: GroupedAffiliationRules[],
) => {
  const groupedRule = groupedAffiliationRules?.find(
    (groupedRules) => groupedRules.affiliationRuleGroupType === ruleGroupType,
  );
  return groupedRule ? groupedRule : { affiliationRuleGroupType: ruleGroupType, affiliationRules: [] };
};

export type ParsedRuleValue = {
  value: string;
  label: string;
};

export const sortPolicyEntities = (policyEntities: PolicyEntity[]) =>
  [...policyEntities].sort((a, b) => {
    if (!a.version) {
      return -1;
    } else if (!b.version) {
      return 1;
    }
    const intA = parseInt(a.version);
    const intB = parseInt(b.version);
    return intA > intB ? -1 : 1;
  });

export const useGroupedAffiliationRuleTexts = (groupedRules: GroupedAffiliationRules) => {
  const { t } = useTranslation();
  const [result, setResult] = useState<ParsedRuleValue>({
    value: '',
    label: t(`policyRules:ruleTypeGroup.${groupedRules.affiliationRuleGroupType}.name`),
  });

  useEffect(() => {
    if (groupedRules.affiliationRules.length === 0) {
      setResult((prev) => {
        return { ...prev, value: t('common:select-off') };
      });
      return;
    }

    switch (groupedRules.affiliationRuleGroupType) {
      case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_TYPE: {
        const ruleValues = groupedRules.affiliationRules[0].ruleValues;
        setResult((prev) => {
          return {
            ...prev,
            value:
              ruleValues.length === 1
                ? t(`employmentTypes:${ruleValues[0].condition}`)
                : `${ruleValues.length.toString()} ${t('policyRules:ruleValues.employment-types')}`,
          };
        });
        break;
      }
      case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_REMUNERATION_TYPE: {
        setResult((prev) => {
          return {
            ...prev,
            value: groupedRules.affiliationRules[0].ruleValues
              .map((value) => t(`remunerationTypes:${value.value}`))
              .join(', '),
          };
        });
        break;
      }

      case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_CATEGORY: {
        setResult((prev) => {
          return {
            ...prev,
            value: groupedRules.affiliationRules[0].ruleValues
              .map((value) => t(`employmentCategories:${value.value}`))
              .join(', '),
          };
        });
        break;
      }
      case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_START_DATE:
      case AffiliationRuleGroupType.RULE_GROUP_EMPLOYMENT_END_DATE: {
        setResult((prev) => {
          return {
            ...prev,
            value: t(`policyRules:ruleValues.valueType.${groupedRules.affiliationRules[0].ruleValues[0].valueType}`),
          };
        });
        break;
      }

      case AffiliationRuleGroupType.RULE_GROUP_YEAR_OF_BIRTH_LIMIT: {
        const ruleValues = groupedRules.affiliationRules[0].ruleValues;
        const fromYear = ruleValues.find((value) => value.valueType === ('GREATER_OR_EQUALS_THAN' || 'GREATER_THAN'));
        const toYear = ruleValues.find(
          (value) => value.valueType === 'LESS_OR_EQUALS_THAN' || value.valueType === 'LESS_THAN',
        );
        const fromYearString = fromYear
          ? `${String.fromCharCode(RuleValueCharCode[fromYear.valueType])}  ${fromYear.value}`
          : '';
        const toYearString = toYear
          ? `${String.fromCharCode(RuleValueCharCode[toYear.valueType])}  ${toYear.value}`
          : '';

        setResult((prev) => {
          return {
            ...prev,
            value:
              fromYearString && toYearString
                ? `${fromYearString}, ${toYearString}`
                : `${fromYearString} ${toYearString} `,
          };
        });
        break;
      }
      case AffiliationRuleGroupType.RULE_GROUP_AGE_LIMIT: {
        const rule = groupedRules.affiliationRules[0];
        const olderThan = rule.ruleValues.find(
          (value) => value.valueType === 'GREATER_OR_EQUALS_THAN' || value.valueType === 'GREATER_THAN',
        );
        const youngerThan = rule.ruleValues.find(
          (value) => value.valueType === 'LESS_OR_EQUALS_THAN' || value.valueType === 'LESS_THAN',
        );
        const individualRetirementAge = rule.ruleValues.some(
          (value) => value.condition === 'INDIVIDUAL_RETIREMENT_AGE',
        );

        const olderThanString = olderThan
          ? `${String.fromCharCode(RuleValueCharCode[olderThan.valueType])} ${olderThan.value}`
          : '';
        const youngerThanString = youngerThan
          ? `${String.fromCharCode(RuleValueCharCode[youngerThan.valueType])} ${youngerThan.value}`
          : '';

        setResult((prev) => {
          return {
            ...prev,
            value:
              `${
                olderThanString && youngerThanString
                  ? `${olderThanString}, ${youngerThanString}`
                  : `${olderThanString} ${youngerThanString}`
              } ${rule.ruleValueUnit ? t(`policyRules:ruleValues.valueUnit.${rule.ruleValueUnit}`) : ''}` +
              (individualRetirementAge ? `, ${t('policyRules:individual-choices.individual-retirement-age')}` : ''),
          };
        });
        break;
      }

      case AffiliationRuleGroupType.RULE_GROUP_LEAVE_LIMIT: {
        const rules = groupedRules.affiliationRules;
        setResult((prev) => {
          return {
            ...prev,
            value: `${rules.length.toString()} ${t('policyRules:ruleValues.types')}`,
          };
        });
        break;
      }

      case AffiliationRuleGroupType.RULE_GROUP_SICK_LEAVE_LIMIT: {
        const rules = groupedRules.affiliationRules;
        setResult((prev) => {
          return {
            ...prev,
            value: `${rules.length.toString()} ${t('policyRules:ruleValues.types')}`,
          };
        });
        break;
      }

      case AffiliationRuleGroupType.RULE_GROUP_WAGE_LIMIT: {
        const rules = groupedRules.affiliationRules;
        setResult((prev) => {
          return {
            ...prev,
            value: `${rules.length.toString()} ${t('policyRules:ruleValues.types')}`,
          };
        });
        break;
      }

      case AffiliationRuleGroupType.RULE_GROUP_INDIVIDUAL_CHOICE: {
        const rules = groupedRules.affiliationRules;
        setResult((prev) => {
          return {
            ...prev,
            value: `${rules.map((rule) => t(`policyRules:policyRuleType:${rule.policyRuleType}`)).join(', ')}`,
          };
        });
        break;
      }
    }
  }, [groupedRules]);
  return result;
};

export const isNotBonusComponent = (type: object): type is PglWageType =>
  (type as PglWageType).calculationTimeFrame !== undefined;

export const isSPglWageType = (wageType: WageType) => ['GROSS_DEDUCTION', 'SALARY_EXCHANGE'].includes(wageType);

const validAccumulatePastTwelveMonthsWageTypes: WageType[] = [
  'VARIABLE_SUPPLEMENT_VACATION',
  'VARIABLE_SUPPLEMENT_NON_VACATION',
];
export const isValidWageTypeTimeFrameCombination = (wageType: WageType, calculationTimeFrame: CalculationTimeFrame) =>
  calculationTimeFrame != 'ACCUMULATE_PAST_TWELVE_MONTHS' ||
  validAccumulatePastTwelveMonthsWageTypes.includes(wageType);

export const shouldWageTypeHaveDenomination = (wageType: WageType | string) =>
  wageType == 'VARIABLE_SUPPLEMENT_VACATION';
