import React, { useState } from 'react';
import QueryWrapper from 'src/components/Common/QueryWrapper/QueryWrapper';
import { SearchInvoicesPage } from 'src/types/invoice/Invoice';
import LoadingPage from 'src/components/Common/LoadingPage';
import { SEARCH_INVOICES } from 'src/graphql/schema/query/invoice/SearchInvoices';
import { getInitialSearchInvoicesRequest } from './utils/InvoiceUtils';
import { SearchInvoicesFilters, SearchInvoicesRequest } from 'src/types/invoice/SearchInvoicesRequest';
import Invoices from './Invoices/Invoices';

const InvoicesTab = () => {
  const [searchInvoicesRequest, setSearchInvoicesRequest] = useState<SearchInvoicesRequest>(
    getInitialSearchInvoicesRequest(),
  );

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  const handleTextSearch = (searchInput: string) => {
    setSearchInvoicesRequest({
      ...searchInvoicesRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchInvoicesFilters) => {
    setSearchInvoicesRequest({ ...searchInvoicesRequest, filters });
  };

  return (
    <QueryWrapper
      query={SEARCH_INVOICES}
      loadingComponent={<LoadingPage />}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          request: {
            searchInput: searchInvoicesRequest?.searchInput,
            pageNumber: pageNumber,
            pageSize: pageSize,
            filters: searchInvoicesRequest?.filters,
          },
        },
      }}
    >
      {({ searchInvoices, paging }: SearchInvoicesPage) => (
        <Invoices
          invoices={searchInvoices}
          paging={paging}
          pageNumber={pageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          searchInvoicesRequest={searchInvoicesRequest}
          onTextSearch={handleTextSearch}
          onApplyFilters={handleApplyFilters}
          onPageChange={handlePageChange}
        />
      )}
    </QueryWrapper>
  );
};

export default InvoicesTab;
