import { FC, useMemo, useState } from 'react';
import InvoicesTable from '../InvoicesTable';
import SearchInvoicesFiltersModal from '../InvoicesTable/SearchInvoicesFiltersModal';
import { Button, Icon, Pagination } from 'fgirot-k2-ui-components';
import { t } from 'i18next';
import { Invoice } from 'src/types/invoice/Invoice';
import { Paging } from 'src/types/PageableResponse';
import { SearchInvoicesFilters, SearchInvoicesRequest } from 'src/types/invoice/SearchInvoicesRequest';
import InvoicesSearchInput from '../InvoicesSearchInput';
import InvoicesFilterDetails from '../InvoicesFilterDetails';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import './invoices.scss';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import When from 'src/components/Common/When';

interface InvoicesProps {
  invoices: Invoice[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchInvoicesRequest: SearchInvoicesRequest;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchInvoicesFilters) => void;
  onPageChange: (pageSize: number) => void;
}

const Invoices: FC<InvoicesProps> = ({
  invoices,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  searchInvoicesRequest,
  onTextSearch,
  onApplyFilters,
  onPageChange,
}) => {
  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );
  const showPagination = totalPages > 0;

  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const handleModalAction = () => setFiltersModalOpen(!filtersModalOpen);

  return (
    <div className="invoices">
      <InvoicesSearchInput onSearch={onTextSearch} searchRequest={searchInvoicesRequest} />
      <InvoicesFilterDetails searchRequest={searchInvoicesRequest} applyFilters={onApplyFilters} />

      <div className="invoices__table">
        <InvoicesTable invoices={invoices} />
      </div>

      {filtersModalOpen && (
        <SearchInvoicesFiltersModal
          open={filtersModalOpen}
          onClose={handleModalAction}
          onApplyFilters={onApplyFilters}
          filters={searchInvoicesRequest?.filters}
        />
      )}
      <When condition={showPagination}>
        <div data-testid="invoices-pagination" className="invoices__pagination">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            pageSizeLabel={t('common:results-per-page')}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={setPageSize}
            pageSizePosition="left"
          />
        </div>
      </When>
      <div className="invoices__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalAction}
          data-cy="filter-button"
        />
      </div>
    </div>
  );
};

export default Invoices;
