import { gql } from '@apollo/client';

export const GET_CONTRACT_SETTING = gql`
  query ContractSetting($key: String!, $insuranceProviderContractId: UUID!) {
    contractSetting(key: $key, insuranceProviderContractId: $insuranceProviderContractId) {
      id
      key
      value
      fromDate
      toDate
    }
  }
`;
