import { Button, Card, CardAction, Icon, Modal, Option, Select, Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { SearchPremiumInvoiceRowsFilters } from 'src/types/invoice/SearchPremiumInvoiceRowsRequest';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { unique } from 'src/util/ArrayUtil';
import { getPeriodMonthString, getPeriodYear } from 'src/util/date/PeriodUtil';
import { Month } from 'src/types/Month';
import {
  convertMonthNumberStringToEnum,
  convertMonthEnumToStringWithZeroes,
  useMonthOptions,
} from 'src/util/date/Months';
import { useYearOptions } from 'src/util/date/Years';
import InfoMessage from 'src/components/Common/InfoMessage';
import './search-premium-invoice-rows-filters-modal.scss';

interface SearchPremiumInvoiceRowsFiltersModalProps {
  open: boolean;
  filters: SearchPremiumInvoiceRowsFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchPremiumInvoiceRowsFilters) => void;
}

const SearchPremiumInvoiceRowsFiltersModal: FC<SearchPremiumInvoiceRowsFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [fromPeriodYear, setFromPeriodYear] = useState<number>(getPeriodYear(filters.periodFilter?.periodStart));
  const [fromPeriodMonth, setFromPeriodMonth] = useState<string>(
    getPeriodMonthString(filters.periodFilter?.periodStart),
  );

  const [toPeriodYear, setToPeriodYear] = useState<number>(getPeriodYear(filters.periodFilter?.periodEnd));
  const [toPeriodMonth, setToPeriodMonth] = useState<string>(getPeriodMonthString(filters.periodFilter?.periodEnd));

  const yearsOptions = useYearOptions(true);
  const monthOptions = useMonthOptions(true);

  const [selectedInsuranceProviderIds, setSelectedInsuranceProviderIds] = useState<string[]>(
    filters.insuranceProviderIdsFilter || [],
  );

  const handleInsuranceProviderFilterChange = (options: Option<string>[]) => {
    setSelectedInsuranceProviderIds(options.map((option) => option.value));
  };

  const onApply = () => {
    const newFilters: SearchPremiumInvoiceRowsFilters = {
      ...filters,
      insuranceProviderIdsFilter: selectedInsuranceProviderIds.length > 0 ? selectedInsuranceProviderIds : null,
      periodFilter: {
        periodStart: fromPeriodYear && fromPeriodMonth ? `${fromPeriodYear}${fromPeriodMonth}` : null,
        periodEnd: toPeriodYear && toPeriodMonth ? `${toPeriodYear}${toPeriodMonth}` : null,
      },
    };
    onApplyFilters(newFilters);
    onClose();
  };

  const useButtonDisabled: boolean =
    (fromPeriodYear !== null && fromPeriodMonth === null) || (toPeriodYear !== null && toPeriodMonth === null);

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="search-premium-invoice-rows-filters-modal"
        title={t('premiumInvoiceRows:filter-modal.title')}
        icon={<Icon type="Sliders" className="search-premium-invoice-rows-filters-modal__icon" />}
        size="wide"
      >
        <div className="search-premium-invoice-rows-filters-modal__filters">
          <QueryWrapper query={GET_INSURANCE_PROVIDERS}>
            {(insuranceProviders: InsuranceProvider[]) => {
              const insuranceProviderOptions = unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'));
              return (
                <div className="search-premium-invoice-rows-filters-modal__select">
                  <Select
                    label={t('premiumInvoiceRows:filter-modal.insurance-provider-label')}
                    placeholder={t('common:select')}
                    selectAll
                    options={insuranceProviderOptions}
                    selected={insuranceProviderOptions.filter((option) =>
                      selectedInsuranceProviderIds.includes(option.value),
                    )}
                    onChange={handleInsuranceProviderFilterChange}
                    small
                    outlined
                    multiple
                  />
                </div>
              );
            }}
          </QueryWrapper>

          <div className="search-premium-invoice-rows-filters-modal__date-section">
            <div className="date-filter">
              <div className="date-filter__period">
                <Typography component="label" variant="caption" className="date-filter__label">
                  {t('common:period-filter.choose-period-start')}
                </Typography>
                <div className="date-filter__period__selects">
                  <Select
                    options={yearsOptions}
                    alphabetical={false}
                    onChange={(e: Option<number>) => setFromPeriodYear(e.value)}
                    selected={yearsOptions.find((option) => option.value === fromPeriodYear)}
                    data-testid="start-year-select"
                    small
                    outlined
                  />
                  <Select
                    options={monthOptions}
                    alphabetical={false}
                    onChange={(e: Option<Month>) => setFromPeriodMonth(convertMonthEnumToStringWithZeroes(e.value))}
                    selected={monthOptions.find(
                      (option) =>
                        option.value ===
                        (fromPeriodMonth === null ? null : convertMonthNumberStringToEnum(fromPeriodMonth)),
                    )}
                    disabled={fromPeriodYear === null}
                    data-testid="start-month-select"
                    small
                    outlined
                  />
                </div>
              </div>
              <div className="date-filter__period">
                <Typography component="label" variant="caption" className="date-filter__label">
                  {t('common:period-filter.choose-period-end')}
                </Typography>
                <div className="date-filter__period__selects">
                  <Select
                    options={yearsOptions}
                    alphabetical={false}
                    onChange={(e: Option<number>) => setToPeriodYear(e.value)}
                    selected={yearsOptions.find((option) => option.value == toPeriodYear)}
                    data-testid="end-year-select"
                    small
                    outlined
                  />
                  <Select
                    options={monthOptions}
                    alphabetical={false}
                    onChange={(e: Option<Month>) => setToPeriodMonth(convertMonthEnumToStringWithZeroes(e.value))}
                    selected={monthOptions.find(
                      (option) =>
                        option.value ===
                        (toPeriodMonth === null ? null : convertMonthNumberStringToEnum(toPeriodMonth)),
                    )}
                    disabled={toPeriodYear === null}
                    data-testid="end-month-select"
                    small
                    outlined
                  />
                </div>
              </div>
            </div>
            <InfoMessage message={t('common:period-filter-info-message')} />
          </div>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button
            label={t('premiumInvoiceRows:filter-modal.use-button')}
            disabled={useButtonDisabled}
            onClick={onApply}
          />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchPremiumInvoiceRowsFiltersModal;
