import React, { useEffect } from 'react';
import './App.scss';
import NavigationBar from './components/PageBase/NavigationBar/NavigationBar';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute, useKeycloak } from 'fgirot-k2-react-keycloak-provider';
import Account from './pages/Account';
import Reporting from './pages/Reporting';
import Report from './pages/Report';
import EmployerSettings from './pages/Account/EmployerSettings';
import ReportingSettings from './pages/Account/EmployerSettings/ReportingSettings';
import PolicySettings from './pages/Account/EmployerSettings/PolicySettings';
import WageTypeMappingSettings from './pages/Account/EmployerSettings/WageTypeMappingSettings';
import { ErrorBoundary } from 'react-error-boundary';
import UnhandledErrorComponent from './components/Common/UnhandledErrorComponent';
import AgreementRegistrySettings from './pages/Account/EmployerSettings/AgreementRegistrySettings';
import Employees from './pages/Employees/Employees';
import EmployeePage from './pages/Employee';
import Economy from './pages/Economy/Economy';
import EventsOverview from './pages/Reporting/Events/EventsOverview';
import InvoiceDetailsPage from './pages/Economy/InvoiceDetailsPage';
import EconomySettings from './pages/Account/EmployerSettings/EconomySettings';
import AccountingUnitDetails from './components/Account/EconomySettings/AccountingUnitDetails';
import AccountingTypePage from './components/Account/EconomySettings/AccountingTypeTab/AccountingTypePage/AccountingTypePage';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ToastsContainer } from 'fgirot-k2-ui-components';
import Documents from './pages/Documents';
import SelectCustomer from './components/SelectCustomer/SelectCustomer';
import useCustomerSelected from './util/Hooks/CustomerSelectedHook';
import Permission from './pages/Permission';
import PostingRuleDetails from './components/Account/EconomySettings/AccountingUnitDetails/PostingRuleDetails';
import EnvironmentInfo from './components/PageBase/EnvironmentInfo';

const App = () => {
  const { t } = useTranslation();
  const { keycloak, initialized } = useKeycloak();
  const customerSelected = useCustomerSelected();

  useEffect(() => {
    document.title = t('common:page-title');
  }, [i18next.language]);

  return (
    initialized && (
      <Router>
        {!keycloak.authenticated ? (
          <>{keycloak.login()}</>
        ) : (
          <PrivateRoute>
            {customerSelected && <NavigationBar />}
            {!customerSelected && <SelectCustomer />}
            <div className="page-container">
              <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => {
                  return <UnhandledErrorComponent error={error} resetErrorBoundary={resetErrorBoundary} />;
                }}
              >
                {customerSelected && (
                  <Routes>
                    <Route path="/reporting/*">
                      <Route index element={<Reporting />} />
                      <Route path=":year/*">
                        <Route index element={<Reporting />} />
                        <Route path=":month/" element={<Report />} />
                        <Route path=":month/insurance-events" element={<EventsOverview />} />
                      </Route>
                    </Route>
                    <Route path="/employees/*">
                      <Route index element={<Employees />} />
                      <Route path=":employeeId/*">
                        <Route index element={<EmployeePage />} />
                        <Route path=":period" element={<EmployeePage />} />
                      </Route>
                    </Route>
                    <Route path="/economy/*">
                      <Route index element={<Economy />} />
                      <Route path="invoices/:invoiceId" element={<InvoiceDetailsPage />} />
                    </Route>
                    <Route path="/documents/*">
                      <Route index element={<Documents />} />
                    </Route>
                    <Route path="/account/*">
                      <Route index element={<Account />} />
                      <Route path=":customerName/:employerId/*">
                        <Route index element={<EmployerSettings />} />
                        <Route path="reporting-settings" element={<ReportingSettings />} />
                        <Route path="policy-settings">
                          <Route index element={<PolicySettings />} />
                          <Route path=":policyId" element={<PolicySettings />} />
                        </Route>
                        <Route path="wage-type-mapping-settings" element={<WageTypeMappingSettings />} />
                        <Route path="agreement-registry-settings" element={<AgreementRegistrySettings />} />
                        <Route path="economy-settings/*">
                          <Route index element={<EconomySettings />} />
                          <Route path="accounting-unit/:accountingUnitId" element={<AccountingUnitDetails />} />
                          <Route path="accounting-type/:accountingTypeId" element={<AccountingTypePage />} />
                          <Route path="posting-rule/:postingRuleId" element={<PostingRuleDetails />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="/permission" element={<Permission />} />
                    <Route path="" element={<Navigate to="/reporting" />} />
                    <Route path="*" element={<Navigate to="/reporting" />} />
                  </Routes>
                )}
              </ErrorBoundary>
            </div>
            <ToastsContainer />
            <EnvironmentInfo />
          </PrivateRoute>
        )}
      </Router>
    )
  );
};

export default App;
