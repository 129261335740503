import { gql } from '@apollo/client';

export const SEARCH_PREMIUM_INVOICE_ROWS = gql`
  query SearchPremiumInvoiceRows(
    $employeeId: UUID!
    $onlyValidatedInvoiceRows: Boolean
    $request: SearchPremiumInvoiceRowsRequest
  ) {
    searchPremiumInvoiceRows(
      employeeId: $employeeId
      onlyValidatedInvoiceRows: $onlyValidatedInvoiceRows
      request: $request
    ) {
      paging {
        totalNumberOfElements
        hasNext
      }
      searchPremiumInvoiceRows {
        id
        insurance {
          id
          name
          insuranceNumber
          agreedProduct {
            id
            name
          }
          agreementNumber
        }
        insuranceTypeMapping {
          id
          name
          benefitType
        }
        insuranceProvider {
          id
          legalName
        }
        periodStart
        periodEnd
        invoiceNumber
        salaryTax
        dueDate
        contractBenefitGroup
        contractBenefitGroupName
        amount
        note
      }
    }
  }
`;
