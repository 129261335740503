import { Button, Card, CardAction, Icon, Modal, Option, Select } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import './search-invoice-rows-filters-modal.scss';
import { SearchInvoiceRowsFilters } from 'src/types/invoice/SearchInvoiceRowsRequest';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { GET_INSURANCE_PROVIDERS } from 'src/graphql/schema/query/insurance/GetInsuranceProviders';
import { createOptionsFromList } from 'src/util/OptionUtil';
import { InsuranceProvider } from 'src/types/InsuranceProvider';
import { unique } from 'src/util/ArrayUtil';

interface SearchInvoiceRowsFiltersModalProps {
  open: boolean;
  filters: SearchInvoiceRowsFilters;
  onClose: () => void;
  onApplyFilters: (newFilters: SearchInvoiceRowsFilters) => void;
}
const SearchInvoiceRowsFiltersModal: FC<SearchInvoiceRowsFiltersModalProps> = ({
  open,
  filters,
  onClose,
  onApplyFilters,
}) => {
  const { t } = useTranslation();

  const [newFilters, setNewFilters] = useState<SearchInvoiceRowsFilters>({ ...filters });

  const handleInsuranceProviderFilterChange = (options: Option<string>[]) => {
    setNewFilters({
      ...newFilters,
      insuranceProviderIdsFilter: options.map((option) => option.value),
    });
  };

  const onApply = () => {
    onApplyFilters(newFilters);
    onClose();
  };

  return (
    <Modal centered overlayBackground open={open} onClose={onClose}>
      <Card
        className="invoice-rows-filters-modal"
        title={t('invoice:invoice-rows.filters-modal.title')}
        icon={<Icon type="Sliders" className="invoice-rows-filters-modal__icon" />}
        size="wide"
      >
        <div className="invoice-rows-filters-modal__filters">
          <QueryWrapper query={GET_INSURANCE_PROVIDERS} type="list">
            {(insuranceProviders: InsuranceProvider[]) => {
              const insuranceProviderOptions = unique(createOptionsFromList(insuranceProviders, 'id', 'legalName'));
              return (
                <div className="search-invoices-filters-modal__select">
                  <Select
                    placeholder={t('common:select')}
                    label={t('invoice:invoice-rows.filters-modal.insurance-mapping-ids-label')}
                    selectAll
                    options={insuranceProviderOptions}
                    selected={insuranceProviderOptions.filter((option) =>
                      newFilters?.insuranceProviderIdsFilter?.includes(option.value),
                    )}
                    onChange={handleInsuranceProviderFilterChange}
                    small
                    outlined
                    multiple
                  />
                </div>
              );
            }}
          </QueryWrapper>
        </div>
        <CardAction>
          <Button label={t('common:cancel')} type="link" onClick={onClose} />
          <Button label={t('invoice:invoice-rows.filters-modal.use-button')} onClick={onApply} />
        </CardAction>
      </Card>
    </Modal>
  );
};

export default SearchInvoiceRowsFiltersModal;
