import React from 'react';
import { EmploymentEventCheckpoint } from 'src/types/Checkpoint';
import { Employee } from 'src/types/Employee';
import When from 'src/components/Common/When';
import UpdateGrossSalaryInfo from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails/UpdateGrossSalaryInfo';
import UpdateStartDate from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails/UpdateStartDate';
import WageTypeChangeInfo from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails/WageTypeChangeInfo';
import UpdateMultipleMonthlySalaryFromDate from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails/UpdateMultipleMonthlySalaryFromDate';
import { EmployeeCard } from 'src/types/EmployeeCard';
import { Typography } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import UpdateWageTypeSums from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/EmployeeCheckpointDetails/UpdateWageTypeSums';
import { ExaminationEmployeeDrawerState } from 'src/components/Report/Examination/ExaminationTable/ExaminationEmployeeDrawer/DrawerState';
import UpdateWagesPayedAmount from './UpdateWagesPayedAmount';
import { SubActionCategory, wageTypeChangeSubActionCategories } from 'src/types/SubActionCategory';
import UpdateIndividualChoiceITP1FromDate from './UpdateIndividualChoiceITP1FromDate';
import UpdateReemployment from './UpdateReemployment';
import UpdateEndDate from './UpdateEndDate';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import NegativeKubSumCorrection from './NegativeKubSumCorrection';
import { GET_REEMPLOYMENT_CHECKPOINT_STATE } from 'src/graphql/schema/query/reporting/GetReEmploymentCheckpointState';
import { ReEmploymentCheckpointState } from 'src/types/reporting/ReEmploymentCheckpointState';
import './employee-checkpoint-details.scss';

interface EmployeeCheckpointDetailsProps {
  checkpoint: EmploymentEventCheckpoint;
  employee: Employee;
  handleNavigation: (state: ExaminationEmployeeDrawerState) => void;
  currentNavigationState: ExaminationEmployeeDrawerState;
  employeeCard: EmployeeCard;
  wageFilePeriod: string;
}

const EmployeeCheckpointDetails: React.FC<EmployeeCheckpointDetailsProps> = ({
  employee,
  checkpoint,
  currentNavigationState,
  handleNavigation,
  employeeCard,
  wageFilePeriod,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4" component="h1" bold className="employee-checkpoint-details__header">
        {t(`subActionCategories:${checkpoint.subActionCategory}`)}
      </Typography>
      <When condition={checkpoint.subActionCategory == 'GROSS_SALARY_CHANGE'}>
        <UpdateGrossSalaryInfo employerId={employee.employerId} employeeCard={employeeCard} />
      </When>
      <When condition={checkpoint.subActionCategory == 'RETROACTIVELY_EMPLOYMENT_START_DATE'}>
        <UpdateStartDate startDate={employee.employmentStartDate} employee={employee} />
      </When>
      <When condition={checkpoint.subActionCategory == 'RETROACTIVELY_EMPLOYMENT_END_DATE'}>
        <UpdateEndDate endDate={employee.employmentEndDate} employee={employee} />
      </When>
      <When condition={checkpoint.subActionCategory == 'REEMPLOYMENT'}>
        <QueryWrapper
          query={GET_REEMPLOYMENT_CHECKPOINT_STATE}
          options={{ variables: { checkpointId: checkpoint.id } }}
        >
          {(checkpointState: ReEmploymentCheckpointState) => (
            <UpdateReemployment
              employee={employee}
              checkpoint={checkpoint}
              employerId={employee.employerId}
              checkpointState={checkpointState}
              onBackClick={() => handleNavigation(ExaminationEmployeeDrawerState.START)}
            />
          )}
        </QueryWrapper>
      </When>
      <When condition={checkpoint.subActionCategory == 'CHANGE_OF_EMPLOYMENT_END_DATE'}>
        <UpdateEndDate endDate={employee.employmentEndDate} employee={employee} />
      </When>
      <When condition={wageTypeChangeSubActionCategories.includes(checkpoint.subActionCategory as SubActionCategory)}>
        <WageTypeChangeInfo
          employeeCard={employeeCard}
          employerId={employee.employerId}
          subActionCategory={checkpoint.subActionCategory as SubActionCategory}
        />
      </When>
      <When condition={checkpoint.subActionCategory == 'MULTIPLE_MONTHLY_SALARY_WAGE_ROWS_VALID_FROMDATE_REQUIRED'}>
        <UpdateMultipleMonthlySalaryFromDate employeeCard={employeeCard} employerId={employee.employerId} />
      </When>
      <When condition={checkpoint.subActionCategory == 'WAGE_TYPES_POSITIVE_SUM_NEGATIVE_REQUIRED'}>
        <UpdateWageTypeSums
          employeeCard={employeeCard}
          employerId={employee.employerId}
          checkpoint={checkpoint}
          currentNavigationState={currentNavigationState}
          onNavigateDrawerState={handleNavigation}
        />
      </When>
      <When condition={checkpoint.subActionCategory === 'RETRO_WAGE_ROWS_FOR_KUB_PAYED_AMOUNT_MISSING'}>
        <UpdateWagesPayedAmount
          checkpoint={checkpoint}
          wageFilePeriod={wageFilePeriod}
          employee={employee}
          employerId={employee.employerId}
          onBackClick={() => handleNavigation(ExaminationEmployeeDrawerState.START)}
        />
      </When>
      <When condition={checkpoint.subActionCategory === 'INDIVIDUAL_CHOICE_ITP1_FROM_PERIOD_REQUIRED'}>
        <UpdateIndividualChoiceITP1FromDate
          checkpoint={checkpoint}
          employeeId={employee.id}
          employmentStartDate={employee.employmentStartDate}
          employerId={employee.employerId}
          onBackClick={() => handleNavigation(ExaminationEmployeeDrawerState.START)}
        />
      </When>
      <When condition={checkpoint.subActionCategory === 'HANDLE_NEGATIVE_KUB'}>
        <NegativeKubSumCorrection
          checkpoint={checkpoint}
          employerId={employee.employerId}
          employeeCard={employeeCard}
          onBackClick={() => handleNavigation(ExaminationEmployeeDrawerState.START)}
        />
      </When>
    </>
  );
};

export default EmployeeCheckpointDetails;
