import React, { useState } from 'react';
import { SearchPremiumInvoiceRowsPage } from 'src/types/invoice/PremiumInvoiceRow';
import QueryWrapper from 'src/components/Common/QueryWrapper';
import { useParams } from 'react-router-dom';
import PremiumInvoiceRows from './PremiumInvoiceRows';
import { SEARCH_PREMIUM_INVOICE_ROWS } from 'src/graphql/schema/query/invoice/SearchPremiumInvoiceRows';
import {
  SearchPremiumInvoiceRowsFilters,
  SearchPremiumInvoiceRowsRequest,
} from 'src/types/invoice/SearchPremiumInvoiceRowsRequest';
import { getInitialSearchPremiumInvoiceRowsRequest } from './PremiumInvoiceRows/utils/premiumInvoiceRowUtils';
import './premium-invoice-rows-overview.scss';

const PremiumInvoiceRowsOverview = () => {
  const [premiumInvoiceRowsRequest, setPremiumInvoiceRowsRequest] = useState<SearchPremiumInvoiceRowsRequest>(
    getInitialSearchPremiumInvoiceRowsRequest(),
  );

  const [pageSize, setPageSize] = useState(25);
  const [pageNumber, setPageNumber] = useState(0);
  const handlePageChange = (page: number) => setPageNumber(page - 1);

  const { employeeId } = useParams();

  const handleTextSearch = (searchInput: string) => {
    setPremiumInvoiceRowsRequest({
      ...premiumInvoiceRowsRequest,
      searchInput,
    });
  };

  const handleApplyFilters = (filters: SearchPremiumInvoiceRowsFilters) => {
    setPremiumInvoiceRowsRequest({ ...premiumInvoiceRowsRequest, filters });
  };

  return (
    <QueryWrapper
      query={SEARCH_PREMIUM_INVOICE_ROWS}
      options={{
        fetchPolicy: 'no-cache',
        variables: {
          employeeId,
          onlyValidatedInvoiceRows: true,
          request: {
            searchInput: premiumInvoiceRowsRequest?.searchInput,
            pageNumber: pageNumber,
            pageSize: pageSize,
            filters: premiumInvoiceRowsRequest?.filters,
          },
        },
      }}
    >
      {({ searchPremiumInvoiceRows, paging }: SearchPremiumInvoiceRowsPage) => {
        return (
          <PremiumInvoiceRows
            premiumInvoiceRows={searchPremiumInvoiceRows}
            paging={paging}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setPageSize={setPageSize}
            request={premiumInvoiceRowsRequest}
            onTextSearch={handleTextSearch}
            onApplyFilters={handleApplyFilters}
            onPageChange={handlePageChange}
          />
        );
      }}
    </QueryWrapper>
  );
};

export default PremiumInvoiceRowsOverview;
