import { gql } from '@apollo/client';

export const CREATE_POLICY_COMMENT = gql`
  mutation CreatePolicyComment($policyId: UUID!, $comment: String!) {
    createPolicyComment(policyId: $policyId, comment: $comment) {
      id
      policyId
      createdBy
      createdDate
      comment
    }
  }
`;
