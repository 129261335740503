import { Employee } from 'src/types/Employee';

export const endDateIsBeforeStartDate = (employeeDraft: Employee, employeeToEdit: Employee) => {
  if (!employeeDraft.employmentStartDate && !employeeToEdit.employmentStartDate) {
    return false;
  }
  if (!employeeDraft.employmentEndDate && !employeeToEdit.employmentEndDate) {
    return false;
  }
  const startDateToUse = employeeToEdit.employmentStartDate ?? employeeDraft.employmentStartDate;
  const endDateToUse = employeeToEdit.employmentEndDate ?? employeeDraft.employmentEndDate;
  return endDateToUse < startDateToUse;
};
