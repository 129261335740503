import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableBody, TableHead, TableRow, Th } from 'fgirot-k2-ui-components';
import { Employer } from 'src/types/employer/Employer';
import CertifyEmployeeDrawer from './CertifyEmployeeDrawer';
import ReportEmployeeDrawer from '../../Common/ReportEmployeeDrawer';
import { InsuranceEventReportEmployeeResponse } from 'src/types/report/ReportEmployeeResponse';
import CertifyTableRow from './CertifyTableRow/CertifyTableRow';
import './certify-table.scss';
import { hasDeclinedCheckpoints } from 'src/util/CheckpointUtil';
import TableBase from 'src/components/Common/TableBase';
import { fetchInsuranceProviders } from 'src/service/insurance/GetInsuranceProviders';
import { InsuranceProvider } from 'src/types/InsuranceProvider';

interface CertifyTableProps {
  reportEmployees: InsuranceEventReportEmployeeResponse[];
  employers: Employer[];
}

const CertifyTable = ({ reportEmployees, employers }: CertifyTableProps) => {
  const { t } = useTranslation();
  const [certifyEmployeeDrawerOpen, setCertifyEmployeeDrawerOpen] = useState(false);
  const [openEmployeeId, setOpenEmployeeId] = useState<string>();
  const [selectedCheckpointId, setSelectedCheckpointId] = useState<string>();
  const [insuranceProviders, setInsuranceProviders] = useState<InsuranceProvider[]>();

  const [infoPageVisible, setInfoPageVisible] = useState(false);

  const head = [
    { name: t('certify:table.name') },
    { name: t('certify:table.person-number') },
    { name: t('certify:table.employer') },
    { name: t('certify:table.employment-rate') },
    { name: t('certify:table.absence') },
    { name: t('certify:table.examination-rule') },
    { name: t('certify:table.benefit') },
    { name: t('certify:table.insurance-provider') },
    { name: '' },
    { name: t('certify:table.done') },
  ];

  const handleEmployeeDrawer = (reportEmployee: InsuranceEventReportEmployeeResponse) => {
    setOpenEmployeeId(reportEmployee.employee.id);
    setCertifyEmployeeDrawerOpen(true);
  };

  const handleCloseEmployeeDrawer = () => {
    setCertifyEmployeeDrawerOpen(false);
    setInfoPageVisible(false);
  };

  const handleInfoPageOpen = (checkpointId: string) => {
    setSelectedCheckpointId(checkpointId);
    setInfoPageVisible(true);
  };

  useEffect(() => {
    fetchInsuranceProviders().then((res) => setInsuranceProviders(res));
  }, []);

  return (
    <>
      <TableBase maxHeightPercentage={50}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`ValidatingTable-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reportEmployees.map((reportEmployee, index) => {
            return (
              <CertifyTableRow
                alert={hasDeclinedCheckpoints(reportEmployee.checkpoints)}
                key={`CertifyTableRow-${reportEmployee.employee.id}-${index}`}
                employee={reportEmployee.employee}
                checkpoints={reportEmployee.checkpoints}
                employeeCard={reportEmployee.employeeCard}
                employers={employers}
                handleEmployeeDrawer={() => handleEmployeeDrawer(reportEmployee)}
                insuranceProviders={insuranceProviders}
              />
            );
          })}
        </TableBody>
      </TableBase>

      {certifyEmployeeDrawerOpen && (
        <ReportEmployeeDrawer
          open={certifyEmployeeDrawerOpen}
          employee={reportEmployees.find((re) => re.employee.id === openEmployeeId).employee}
          handleClose={handleCloseEmployeeDrawer}
          infoPageVisible={infoPageVisible}
          onInfoPageClose={() => setInfoPageVisible(false)}
        >
          <CertifyEmployeeDrawer
            infoPageVisible={infoPageVisible}
            reportEmployee={reportEmployees.find((re) => re.employee.id === openEmployeeId)}
            handleInfoPageOpen={handleInfoPageOpen}
            selectedCheckpointId={selectedCheckpointId}
            insuranceProviders={insuranceProviders}
          />
        </ReportEmployeeDrawer>
      )}
    </>
  );
};

export default CertifyTable;
