import { TableHead, TableRow, Th, TableBody, Pagination } from 'fgirot-k2-ui-components';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableBase from 'src/components/Common/TableBase';
import DeviationTableRow from '../DeviationTableRow';
import DeviationDrawer from '../DeviationDrawer/DeviationDrawer';
import When from 'src/components/Common/When';
import { EmployeeDeviation } from '../../../../../types/EmployeeDeviation';
import { createPageSizeOptions } from '../../../../../util/OptionUtil';
import { getTotalPages } from '../../../../../util/Pagination/Pagination';

interface DeviationsTableProps {
  employeeDeviations: EmployeeDeviation[];
  paging;
  pageNumber;
  pageSize;
  setPageSize;
  onPageChange;
}

const DeviationsTable = ({
  employeeDeviations,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  onPageChange,
}: DeviationsTableProps) => {
  const { t } = useTranslation();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();
  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const totalPages = useMemo(
    () => getTotalPages(paging.totalNumberOfElements, pageSize),
    [paging.totalNumberOfElements, pageSize],
  );

  const showPagination = totalPages > 0;

  const head = [
    { name: t('validating:deviations-tab.table.name') },
    { name: t('validating:deviations-tab.table.person-number') },
    { name: t('validating:deviations-tab.table.employer') },
    { name: t('validating:deviations-tab.table.employment-number') },
    { name: t('validating:deviations-tab.table.sub-action-category') },
  ];

  const handleOpenDrawer = (id: string) => {
    setSelectedEmployeeId(id);
  };

  const handleCloseDrawer = () => {
    setSelectedEmployeeId(undefined);
  };

  return (
    <>
      <TableBase maxHeightPercentage={45}>
        <TableHead filled>
          <TableRow>
            {head.map((title, index) => (
              <Th key={`DeviationsTableHead-${title.name}-${index}`}>{title.name}</Th>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeDeviations?.map((employee, index) => {
            return (
              <DeviationTableRow
                key={`DeviationTableRow-${employee.id}-${index}`}
                employeeDeviation={employee}
                employer={employee.employer}
                onClick={() => handleOpenDrawer(employee.id)}
              />
            );
          })}
        </TableBody>
      </TableBase>
      <When condition={selectedEmployeeId !== undefined}>
        <DeviationDrawer
          open={selectedEmployeeId !== undefined}
          employerLegalName={
            employeeDeviations?.find((employee) => employee.id === selectedEmployeeId)?.employer?.legalName
          }
          employeeDeviation={employeeDeviations?.find(
            (employeeDeviation) => employeeDeviation.id === selectedEmployeeId,
          )}
          onClose={handleCloseDrawer}
        />
      </When>
      <When condition={showPagination}>
        <div data-testid="invoices-pagination" className="invoices__pagination">
          <Pagination
            currentPage={pageNumber + 1}
            totalPages={totalPages}
            onPageChange={onPageChange}
            pageSize={pageSize}
            pageSizeLabel={t('common:results-per-page')}
            pageSizeOptions={pageSizeOptions}
            onPageSizeChange={setPageSize}
            pageSizePosition="left"
          />
        </div>
      </When>
    </>
  );
};

export default DeviationsTable;
