import { TableRow, Td, Button, Icon } from 'fgirot-k2-ui-components';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stringOrBlank } from 'src/util/StringUtil';
import { InsuranceTypeMapping } from 'src/types/insurancetypemapping/InsuranceTypeMapping';
import AddPostingRuleModal from '../AddPostingRuleModal/AddPostingRuleModal';

interface InsuranceTypeMappingTableRowProps {
  insuranceTypeMapping: InsuranceTypeMapping;
  chartOfAccountsId: string;
}

const InsuranceTypeMappingTableRow: FC<InsuranceTypeMappingTableRowProps> = ({
  insuranceTypeMapping,
  chartOfAccountsId,
}) => {
  const [selectedInsuranceTypeMappingId, setSelectedInsuranceTypeMappingId] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const handleOpenModal = (id: string) => {
    setModalOpen(true);
    setSelectedInsuranceTypeMappingId(id);
  };

  const toggleModalOpen = () => setModalOpen((prev) => !prev);

  return (
    <>
      <TableRow>
        <Td>{stringOrBlank(insuranceTypeMapping.insuranceProvider.legalName)}</Td>
        <Td>{insuranceTypeMapping.name ? stringOrBlank(insuranceTypeMapping.name) : '-'}</Td>
        <Td>
          {insuranceTypeMapping.benefitType
            ? stringOrBlank(t(`benefitTypes:${insuranceTypeMapping.benefitType}`))
            : '-'}
        </Td>
        <Td>{stringOrBlank(t(`insuranceTypeMapping:insurance-category.${insuranceTypeMapping.insuranceCategory}`))}</Td>
        <Td>
          {insuranceTypeMapping.taxRule
            ? stringOrBlank(t(`insuranceTypeMapping:tax-rule.${insuranceTypeMapping.taxRule}`))
            : '-'}
        </Td>
        <Td>{stringOrBlank(t(`insuranceTypeMapping:premium-source.${insuranceTypeMapping.premiumSource}`))}</Td>
        <Td>
          <Button
            label={t('economySettings:insurance-type-mappings-tab.table.create-posting-rule-button')}
            icon={<Icon type={'PlusLarge'} stroke="white" />}
            type="primary"
            onClick={() => handleOpenModal(insuranceTypeMapping.id)}
          />
        </Td>
      </TableRow>
      {selectedInsuranceTypeMappingId && (
        <AddPostingRuleModal
          open={modalOpen}
          onClose={toggleModalOpen}
          chartOfAccountsId={chartOfAccountsId}
          insuranceTypeMapping={insuranceTypeMapping}
        />
      )}
    </>
  );
};
export default InsuranceTypeMappingTableRow;
