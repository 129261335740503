import { Button, Icon, ListGroup, Typography } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DrawerBase from 'src/components/Common/DrawerBase';
import DrawerModal from 'src/components/Common/DrawerModal';
import ListProperty from 'src/components/Common/ListProperty';
import { stringOrBlank } from 'src/util/StringUtil';
import { EmployeeDeviation } from '../../../../../types/EmployeeDeviation';
import './devations-drawer.scss';

interface DeviationDrawerProps {
  open: boolean;
  onClose: () => void;
  employerLegalName: string;
  employeeDeviation: EmployeeDeviation;
}

const DeviationDrawer = ({ open, onClose, employerLegalName, employeeDeviation }: DeviationDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/employees/${employeeDeviation.id}`);
  };

  return (
    <DrawerModal overlayBackground overlayOnClose open={open} onClose={onClose}>
      <DrawerBase
        leftButton={
          <Button
            type="link"
            label={t('report:employee-drawer.go-to-profile')}
            iconPlacement="left"
            icon={<Icon type="User" className="employee-drawer__button__icon" />}
            onClick={navigateToProfile}
          />
        }
        onClose={onClose}
        title={`${stringOrBlank(employeeDeviation?.firstName)} ${stringOrBlank(employeeDeviation?.lastName)}`}
        subTitle={stringOrBlank(employeeDeviation?.personNumber)}
      >
        <ListGroup variant="inline-edit">
          <ListProperty label={t('validating:deviation-drawer.employer-legal-name-label')} value={employerLegalName} />
          <ListProperty
            label={t('validating:deviation-drawer.employment-number-label')}
            value={employeeDeviation.employmentNumber}
          />
        </ListGroup>
        <Typography className="deviations-drawer__deviations-list" variant="body1" component="h3" bold>
          {t('reporting:checkpoints:employee-deviation-title')}
        </Typography>
        <ListGroup variant="inline-edit">
          {employeeDeviation.deviations.map((deviation) => (
            <ListProperty label={t('reporting:checkpoints.' + deviation)} value={deviation} />
          ))}
        </ListGroup>
      </DrawerBase>
    </DrawerModal>
  );
};

export default DeviationDrawer;
