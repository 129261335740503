import { SortDirection, TableRow, Th } from 'fgirot-k2-ui-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceTableSortField } from './InvoiceTableSortField';
import { TableHeadProps } from 'src/types/TableHeadProps';

interface InvoiceTableHeadsProps {
  sortDirection: SortDirection;
  sortField: InvoiceTableSortField;
  onSortDirectionChange: () => void;
  onSortFieldChange: (sortField: InvoiceTableSortField) => void;
}

const InvoiceTableHeads: FC<InvoiceTableHeadsProps> = ({
  sortDirection,
  sortField,
  onSortDirectionChange,
  onSortFieldChange,
}) => {
  const { t } = useTranslation();
  const tableHeads: TableHeadProps[] = [
    {
      title: t('invoice:table.insurance-provider'),
      sortDirection,
      fieldName: 'insuranceProvider.legalName',
      handleSortDirectionChange: () => {
        onSortDirectionChange();
        onSortFieldChange('insuranceProvider.legalName');
      },
    },
    {
      title: t('invoice:table.agreement-number'),
      sortDirection,
      fieldName: 'agreementNumber',
      handleSortDirectionChange: () => {
        onSortFieldChange('agreementNumber');
        onSortDirectionChange();
      },
    },
    {
      title: t('invoice:table.invoice-number'),
      sortDirection,
      fieldName: 'invoiceNumber',
      handleSortDirectionChange: () => {
        onSortFieldChange('invoiceNumber');
        onSortDirectionChange();
      },
    },
    {
      title: t('invoice:table.invoice-amount'),
      sortDirection,
      fieldName: 'totalAmount',
      handleSortDirectionChange: () => {
        onSortFieldChange('totalAmount');
        onSortDirectionChange();
      },
    },
    {
      title: t('invoice:table.invoice-remaining-amount'),
      sortDirection,
      fieldName: 'remainingAmount',
      handleSortDirectionChange: () => {
        onSortFieldChange('remainingAmount');
        onSortDirectionChange();
      },
    },
    {
      title: t('invoice:table.ocr-number'),
      sortDirection,
      fieldName: 'ocrNumber',
      handleSortDirectionChange: () => {
        onSortFieldChange('ocrNumber');
        onSortDirectionChange();
      },
    },
    {
      title: t('invoice:table.due-date'),
      sortDirection,
      fieldName: 'dueDate',
      handleSortDirectionChange: () => {
        onSortFieldChange('dueDate');
        onSortDirectionChange();
      },
    },
    {
      title: t('invoice:table.invoice-status'),
      sortDirection,
      fieldName: 'invoiceStatus',
      handleSortDirectionChange: () => {
        onSortFieldChange('invoiceStatus');
        onSortDirectionChange();
      },
    },
  ];
  return (
    <TableRow>
      {tableHeads.map((head, index) => {
        return (
          <Th
            includeSortButtons
            sortDirection={head.fieldName === sortField ? sortDirection : undefined}
            key={`invocie-table-head-${index}`}
            handleSortDirectionChange={head.handleSortDirectionChange}
          >
            {head.title}
          </Th>
        );
      })}
    </TableRow>
  );
};

export default InvoiceTableHeads;
