import React, { FC, useMemo, useState } from 'react';
import { Paging } from 'src/types/PageableResponse';
import { getTotalPages } from 'src/util/Pagination/Pagination';
import { Button, Icon, Loader, Pagination } from 'fgirot-k2-ui-components';
import { useTranslation } from 'react-i18next';
import { AccountingItem } from 'src/types/ledgerStorage/AccountingItem';
import {
  SearchAccountingItemsRequest,
  SearchAccountingItemsRequestFilters,
} from 'src/types/ledgerStorage/SearchAccountingItemsRequest';
import AccountingItemsTable from './AccountingItemsTable';
import AccountingItemsFiltersModal from './AccountingItemsFiltersModal';
import AccountingItemsSearchInput from './AccountingItemsSearchInput';
import { exportAccountingItems } from 'src/service/accountingitem/ExportAccountingItems';
import AccountingItemsFilterDetails from './AccountingItemsFilterDetails';
import When from 'src/components/Common/When';
import { createPageSizeOptions } from 'src/util/OptionUtil';
import { useCustomer } from 'src/service/customer/GetCustomer';

interface AccountingItemsProps {
  accountingItems: AccountingItem[];
  paging: Paging;
  pageNumber: number;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  searchRequest: SearchAccountingItemsRequest;
  onTextSearch: (searchInput: string) => void;
  onApplyFilters: (filters: SearchAccountingItemsRequestFilters) => void;
  onPageChange: (nextPageNumber: number) => void;
}

const AccountingItems: FC<AccountingItemsProps> = ({
  accountingItems,
  paging,
  pageNumber,
  pageSize,
  setPageSize,
  searchRequest,
  onTextSearch,
  onApplyFilters,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const pageSizeOptions = createPageSizeOptions([25, 50, 100]);
  const customer = useCustomer();

  const [loading, setLoading] = useState(false);

  const totalPages = useMemo(
    () => getTotalPages(paging?.totalNumberOfElements, pageSize),
    [paging?.totalNumberOfElements, pageSize],
  );

  const showPagination = totalPages > 0;
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const handleCloseModal = () => {
    setFiltersModalOpen(false);
  };

  const handleModalOpen = () => {
    setFiltersModalOpen(true);
  };

  const handleExportButton = () => {
    setLoading(true);
    exportAccountingItems(searchRequest).finally(() => setLoading(false));
  };
  return (
    <>
      <div className="accounting-items-tab">
        <AccountingItemsSearchInput onSearch={onTextSearch} searchRequest={searchRequest} />
        <AccountingItemsFilterDetails searchRequest={searchRequest} applyFilters={onApplyFilters} />
        <div className="accounting-items-tab__export-container">
          <Button
            disabled={accountingItems == undefined || accountingItems?.length <= 0}
            className="accounting-items-tab__export-container__button"
            label={
              t('economy:accounting-items-tab.download-button') +
              (paging?.totalNumberOfElements ? ` (${paging?.totalNumberOfElements})` : ' (0)')
            }
            onClick={handleExportButton}
          />
          <When condition={loading}>
            <Loader type="blue" size="medium"></Loader>
          </When>
        </div>
        <When condition={accountingItems != undefined}>
          <AccountingItemsTable accountingItems={accountingItems} />
        </When>
        <When condition={showPagination}>
          <div className="accounting-items-tab" data-testid="accounting-items-tab-pagination">
            <Pagination
              currentPage={pageNumber + 1}
              totalPages={totalPages}
              onPageChange={onPageChange}
              pageSize={pageSize}
              pageSizeLabel={t('common:results-per-page')}
              pageSizeOptions={pageSizeOptions}
              onPageSizeChange={setPageSize}
              pageSizePosition="left"
            />
          </div>
        </When>
      </div>
      <When condition={filtersModalOpen}>
        <AccountingItemsFiltersModal
          open={filtersModalOpen}
          onClose={handleCloseModal}
          onApplyFilters={onApplyFilters}
          filters={searchRequest?.filters}
          customerId={customer?.id}
        />
      </When>
      <div className="accounting-items-tab__filter-button">
        <Button
          type="floating-action-button"
          icon={<Icon type={'Sliders'} stroke="white" />}
          label={t('common:filter')}
          onClick={handleModalOpen}
          data-testid="filter-button"
          data-cy="filter-button"
        />
      </div>
    </>
  );
};

export default AccountingItems;
