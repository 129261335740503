import { TableRow, Td } from 'fgirot-k2-ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmployerKub } from 'src/types/employer/EmployerKub';
import { EmployerPayType } from 'src/types/employer/EmployerPayType';
import KubTypeChecks from './KubTypeChecks';

interface PayTypeTableRowProps {
  employerPayType: EmployerPayType;
  handleWageTypeMappingDrawer: (id: string) => void;
  employerKubs: EmployerKub[];
}
const PayTypeTableRow: React.FunctionComponent<PayTypeTableRowProps> = ({
  employerPayType,
  handleWageTypeMappingDrawer,
  employerKubs,
}) => {
  const { t } = useTranslation();
  const handleOnClick = () => handleWageTypeMappingDrawer(employerPayType.id);

  return (
    <TableRow onClick={handleOnClick}>
      <Td>{`${employerPayType.number} - ${employerPayType.name}`}</Td>
      <KubTypeChecks employerKubs={employerKubs} employerPayType={employerPayType} />
      <Td>{employerPayType.employerWageType.name}</Td>
      <Td>
        {employerPayType.inUse
          ? t('account:employers-tab.wage-type-mapping-settings.in-use:true')
          : t('account:employers-tab.wage-type-mapping-settings.in-use:false')}
      </Td>
    </TableRow>
  );
};

export default PayTypeTableRow;
