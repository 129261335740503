import { Pill, Typography, VariantType } from 'fgirot-k2-ui-components';
import './calculation-label.scss';
import { PglType } from 'src/types/policy/PglWageType';

interface CalculationLabelProps {
  label: string;
  type: PglType | 'KUB';
  variant?: VariantType;
}

const CalculationLabel = ({ label, type, variant = 'body2' }: CalculationLabelProps) => {
  return (
    <div className="calculation-label">
      <Typography variant={variant} bold>
        {label}
      </Typography>
      <Pill size={'small'} type="neutral" label={type} />
    </div>
  );
};

export default CalculationLabel;
