import { gql } from '@apollo/client';

export const BALANCING_OF_BOOKS_SETTINGS = gql`
  query GetBalancingOfBooksSettings($employerId: UUID!) {
    balancingOfBooksSettings(employerId: $employerId) {
      id
      annualStartDate
      annualEndDate
      dayOfMonth
      useMonthlyAdjustments
      balancingOfBooksAdjustments {
        id
        month
        day
      }
    }
  }
`;
