import { Tab, Tabs } from 'fgirot-k2-ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Policy } from 'src/types/policy/Policy';
import { PolicyEntity } from 'src/types/policy/PolicyEntity';
import PolicyBenefits from '../PolicyBenefits';
import './policy-tabs.scss';
import PolicyEmploymentGroupsTab from '../PolicyEmploymentGroupsTab';
import PolicyEmploymentGroupDrawer from '../PolicyEmploymentGroupModal/PolicyEmploymentGroupModal';
import PolicyCalculationsTab from '../PolicyCalculationsTab';
import useTabParams from 'src/components/hooks/useTabParams';
import { PolicyTab } from 'src/types/tabs/PolicyTab';
import { GET_POLICY } from 'src/graphql/schema/query/policy/GetPolicy';
import QueryWrapper from 'src/components/Common/QueryWrapper';

interface PolicyTabsProps {
  policyEntity: PolicyEntity;
  employerId: string;
}

const PolicyTabs = ({ policyEntity, employerId }: PolicyTabsProps) => {
  const [selectedPolicyEmploymentGroupId, setSelectedPolicyEmploymentGroupId] = useState<string>();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useTabParams('tab', PolicyTab.EMPLOYMENT_GROUPS);

  const handleCardClick = (policyEmploymentGroupId: string) => {
    setSelectedPolicyEmploymentGroupId(policyEmploymentGroupId);
  };

  const handleClose = () => {
    setSelectedPolicyEmploymentGroupId(undefined);
  };

  return (
    <QueryWrapper query={GET_POLICY} options={{ variables: { policyId: policyEntity?.id } }}>
      {(policy: Policy) => {
        return (
          <>
            <Tabs className="policy-tabs" tabIndex={currentTab} onChange={setCurrentTab}>
              <Tab
                data-cy="policy-employment-groups-tab-button"
                key="policySettingsOverview"
                title={t('account:employers-tab.policy-settings.employment-groups')}
              >
                <PolicyEmploymentGroupsTab
                  policyEntity={policyEntity}
                  policyEmploymentGroups={policy.policyEmploymentGroups}
                  handleSelect={handleCardClick}
                />
              </Tab>
              <Tab
                data-cy="policy-benefits-tab-button"
                key="policySettingsBenefits"
                title={t('account:employers-tab.policy-settings.benefits')}
              >
                <PolicyBenefits
                  policyEntity={policyEntity}
                  policyEmploymentGroups={policy.policyEmploymentGroups}
                  policyBenefits={policy.policyBenefits}
                  employerId={employerId}
                />
              </Tab>
              <Tab
                data-cy="policy-calculations-tab-button"
                key="policySettingsPglCalculationsTab"
                title={t('account:employers-tab.policy-settings.pgl-calculation')}
              >
                <PolicyCalculationsTab policyEntity={policyEntity} />
              </Tab>
            </Tabs>
            {selectedPolicyEmploymentGroupId && (
              <PolicyEmploymentGroupDrawer
                employerId={policyEntity.employerId}
                policyId={policyEntity.id}
                open={selectedPolicyEmploymentGroupId !== undefined}
                policyEntityState={policyEntity.state}
                policyEmploymentGroup={policy.policyEmploymentGroups.find(
                  (group) => group.id === selectedPolicyEmploymentGroupId,
                )}
                onClose={handleClose}
              />
            )}
          </>
        );
      }}
    </QueryWrapper>
  );
};

export default PolicyTabs;
